'use strict';

function ODataDataSourceProxy() { }

ODataDataSourceProxy.prototype = {
    init: function (id, url, datasourcename, datamodel, columns, pkey) {
        this.id = id;
        this.url = url;
        this.dataSourceName = datasourcename;
        this.datamodel = datamodel;
        this.columns = columns;
        this.primarykey = pkey;
    },

    /**
     * Funkcja tworzy dataSource dla kontrolki Tree
     * @param {boolean} paged - obecnie do niczego nie wykorzystywany
     * @param {function} onChangeFn - funkcja, która ma się wykonywać na zmianę w datasource
     * @param {string} keyField - Pole klucza
     * @param {string} parentField - Pole rodzica
     * @param {string} textField - Pole z któego ma być pobierana nazwa
     * @param {string} order - typ sortowania
     */
    TreeListDataSource: function (paged, onChangeFn, keyField, parentField, textField, order) {
        paged = !!paged;//convert undefined/null/false to false

        var entityUrl = this.url + this.dataSourceName;
        var self = this;
        this.datamodel.id = keyField;
        this.datamodel.fields[keyField].nullable = false;
        this.datamodel.fields[keyField].editable = false;

        //dodajemy w ktorym przekazujemy informacje na pola ktore trzyma informacje na temat rodzica
        this.datamodel.fields["parentId"] = {};
        this.datamodel.fields["parentId"].nullable = true;
        this.datamodel.fields["parentId"].field = parentField;

        //na razie zawsze zakładamy, że ma dzieci

        var val = "";
        if (this.datamodel.fields[parentField].type == 'number')
            val = null;

        var customHeaders = App.communication.getOdataCustomHeaders(true);

        var datasource = {
            type: "odata-v4",
            transport: {
                read: {
                    url: entityUrl,
                    dataType: "json",
                    cache: false,
                    data : customHeaders
                }
            },
            schema: {
                model: this.datamodel
            },
            error: function (a) {
                console.error("TREE error: " + JSON.stringify(a));
            },
            requestEnd: function (e) {
            },
            sort: order,
            serverPaging: false,
            serverFiltering: true,
            serverSorting: true,
            change: onChangeFn
        };

        this.datasource = new kendo.data.TreeListDataSource(datasource);
        return this.datasource;
    },

    /**
     * Funkcja tworzy dataSource dla kontrolek (grid, combobox, dropdown, checkcombobox)
     * @param {boolean} paged - ilość rekordów na stronie grida
     * @param {function} onChangeFn - funkcja, która ma się wykonywać na zmianę w datasource
     * @param {string} groups - obiekt konfigurujący grupowanie w gridzie
     * @param {string} order - typ sortowania
     * @param {string} onErrorFn - funkcja, która ma się wykonywać na wystąpienie błędu w datasource
     * @param {string} pageSize - ilość pozycji na stronie
     */
    getKendoDataSource: function (paged, onChangeFn, groups, order, onErrorFn, pageSize) {
        paged = !!paged;//convert undefined/null/false to false

        var entityUrl = this.url + this.dataSourceName;

        //TODO: czy poprawić ?
        this.datamodel.id = this.primarykey;
        this.datamodel.fields[this.primarykey].nullable = true;
        this.datamodel.fields[this.primarykey].editable = false;

        for (var property in this.datamodel.fields) {
            this.datamodel.fields[property].editable = true;
        }

        var customHeaders = App.communication.getOdataCustomHeaders(true);

        var aggregatedFields = getAggregateFunc(this.datamodel.fields);

        var datasource = {
            type: "odata-v4",
            transport: {
                read: {
                    url: entityUrl,
                    dataType: "json",
                    cache: false,
                    data: customHeaders
                }
            },
            schema: {
                model: this.datamodel,
                data: function (data) {
                    if (!data.value || data.value.length == 0) {
                        if (onErrorFn) {
                            onErrorFn();
                        }
                        return [];
                    }
                    else
                        return data.value;
                },
                errors: "error"
            },
            error: function (a) {
                console.error("GRID error: " + JSON.stringify(a));
            },
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            sort: order,
            change: onChangeFn,
            aggregate: aggregatedFields
        };

        if (paged)
           datasource.pageSize = parseInt(pageSize ? pageSize : App.Settings.ODataPageSize);

        if (groups)
            datasource.group = groups;

        this.datasource = new kendo.data.DataSource(datasource);
        this.datasource.aggregatedFields = aggregatedFields;
        return this.datasource;
    }
}

function getAggregateFunc(fields) {
    var agr = [];
    for (var f in fields) {
        if (fields[f].type == "number") {
            agr.push({ field: f.toString(), aggregate: "sum" });
        }
    }
    return agr;
}

function ODataProxy() { }

ODataProxy.prototype = {

    init: function (url) {
        this.serviceUrl = url;
        this.serviceMetadataUrl = url + "/$metadata";
        this.cache = new Hashmap(
        function (item) {
            return item.id;
        });
    },

    mapEdmToType: function (edmdatatype) {
        switch (edmdatatype) {
            case 'Edm.Int16':
            case 'Edm.Int32':
            case 'Edm.Double':
            case 'Edm.Single':
            case 'Edm.Decimal':
                return { name: 'number', defaultValue: 0 };
                break;
            case 'Edm.DateTimeOffset':
                return { name: 'date', defaultValue: '' }
                break;
            case 'Edm.String':
            default:
                return { name: 'string', defaultValue: '' };
                break;
        }
    },

    _build: function (data, entityName, entityType) {
        var split = entityType.split('.');
        var namespace = split[0];
        var type = split[1];
        var idno = entityName.substring(15);
        if (idno) {
            var entityTypes = data.find("Schema[Namespace='" + namespace + "']");
            if (entityTypes.length > 0) {
                entityTypes = entityTypes.find("EntityType[Name='" + type + "']");
                if (entityTypes.length > 0) {
                    var properties = $("Property", entityTypes);
                    var keys = $("Key>PropertyRef", entityTypes);

                    var columns = [];
                    var model = { fields: {} };

                    for (var i = 0; i < properties.length; i++) {
                        var $p = $(properties[i]);
                        var colname = $p.attr('Name');
                        var coltype = $p.attr('Type');
                        var nullable = $p.attr('Nullable');
                        if (nullable && nullable === 'false') {
                            nullable = false;
                        } else nullable = true;

                        columns.push(colname);
                        var type = this.mapEdmToType(coltype);
                        var descobject = { type: type.name, nullable: nullable };

                        if (!nullable) {
                            descobject.defaultValue = type.defaultValue;
                        }
                        model.fields[colname] = descobject;
                    }

                    var pkey;

                    if (keys.length != 1)
                        console.warn("SGRID: Kendo support only sipmple primary keys, first will be used.");
                    else {
                        pkey = $(keys[0]).attr('Name');
                    }

                    var dsproxy = new ODataDataSourceProxy();
                    dsproxy.init(idno, this.serviceUrl, entityName, model, columns, pkey);
                    this.cache.add(dsproxy);
                }
            }
        }
    },



    _fetchMetadata: function(onSuccess) {
        var self = this;
        var customHeaders = App.communication.getOdataCustomHeaders()
        $.ajax({
            async: false,
            url: self.serviceMetadataUrl,
            converters: { "text xml": jQuery.parseXML },
            dataType: "xml",
            headers: customHeaders,
            success: onSuccess
        })
    },

    build: function(id) {

        if(this.lastRequest) {
            if(this._searchAndBuild(this.lastRequest,id))
                return;
        }

        var self = this;
        this._fetchMetadata(function (data) {
            var $data = $(data);
            if(typeof data !== 'undefined')
            {
                  self.lastRequest = $data
                  self._searchAndBuild($data,id)
            }
        });
    },

    _searchAndBuild : function(data, id) {
        var name = "DATASETINSTANCE"+id;
        var entitySets = data.find("EntitySet[Name='"+name+"']");
        if(entitySets.length==1) {
            var entityType = $(entitySets[0]).attr("EntityType");
            this._build(data, name, entityType);
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.l2++;
            }
            return true;
        } else {
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.miss++;
            }
            //to że nie znaleziono to niekoniecznie jest błąd
            //komentuje, żeby ludzi nie myliło, jednocześnie zostawiam, bo
            //to może być błąd
            //console.info("Znaleziono "+entitySets.length+" źródeł danych "+name);
        }
        return false;
    },

    buildAll : function () {
        if (this.cache)
            this.cache.clear();

        var self = this;
        this._fetchMetadata(function (data) {
            var $data = $(data);
            var entitySets = $data.find("EntitySet");
            for (var i = 0; i < entitySets.length; i++) {
                var entityName = $(entitySets[i]).attr("Name");
                var entityType = $(entitySets[i]).attr("EntityType");
                self._build($data, entityName, entityType);
            }
        });
    },

    getAll: function () {
        if (this.cache)
            return this.cache.getAll();
    },

    get: function (id) {
        if(App.debug.odata.stat) {
            if(typeof App.debug.odata.statdata == 'undefined')
            {
                App.debug.odata.statdata = {
                    found:0,
                    notfound:0,
                    l1:0,
                    l2:0,
                    miss:0
                };
            }
        }

        var ret;
        if (this.cache)
            ret = this.cache.getValue(id);
        if(!ret) {
            this.build(id);
            ret = this.cache.getValue(id);
        } else {
            if(App.debug.odata.stat) {
                App.debug.odata.statdata.l1++;
            }
        }
        return ret;
    },

    /**
     * Metoda usuwa dane źródło danych z cache'a
     * @param  {string} id Id źródła jest do usunięcia
     */
    remove: function(id) {
        if(this.cache) {
            this.cache.remove({id:id});
        }
    },
}

'use strict';

var FormStack = {
    windowForms: [],
    stackForms: [],

    init: function (navigator) {
        this.navigator = navigator;
        // W nawigatorze funkcja hideSearchBar() zawsze pokazuje przycisk lupki. Funkcja hideSearch() wywołuje hideSearchBar().
        // Także inne funkcje wyołują tą metode. Z tego powodu czasami przycisk migał (pojawiał się i znikał) przy zmianie jego widoczności
        // poniższa flaga służy do sterowania wywoływania widoczności przycisku w funkcji hideSearchBar()
        this.navigator.disableSearch();
    },

    callStaticMethod: function (objectname, methodname, parameters, xmlparameters) {
        App.communication.BP.staticMethod(objectname, methodname, parameters, xmlparameters);
    },

    addWindowToStack: function (id, formStyle, formXml, formUUID, messageId, mode) {
        //ustalamy jaki rodzaj okna będzie uruchomiony
        var targetObject;

        var style = App.gui.currentCss.attr('href');
        if (style.indexOf("tablet") >= 0) {
            //if (App.gui.device.tablet || App.gui.device.mobile) {
            targetObject = SMDIForm;
        }
        else if (mode == "D") {
            /*if (App.gui.device.tablet || App.gui.device.mobile) {
                targetObject = SDictMobileForm;
            }
            else {*/
            if (!App.gui.mobileVersion) {
                targetObject = SDictForm;
            } else {
                targetObject = SMobileWindowForm;
            }
            //}
        }
        else if (formStyle != "M") {
            if (!App.gui.mobileVersion)
                targetObject = SWindowForm;
            else
                targetObject = SMobileWindowForm;
        }
        else {
            targetObject = SMDIForm;
        }

        //tworzymy nową formę i podpinamy pod DOM
        var newForm = Object.create(targetObject);
        newForm.init(formXml, id, formUUID);
        newForm.render(false, messageId);
        newForm.doAfterRender();

        //jeżeli nowa forma nie jest MDI to dodajemy ją do stosu
        if (targetObject == SMDIForm || targetObject == SMobileWindowForm) {
            this.stackForms.push(newForm);

            //odpinamy biezaca forme
            if (this.currentMDIForm) {
                this.currentMDIForm.detach();
                this.currentMDIForm.stackCurrentForm = false;
            }

            this.currentMDIForm = newForm;
            this.currentMDIForm.stackCurrentForm = true

            App.gui.navigator.addFormTab(newForm); //dodajemy zakładkę dla formy
            App.gui.navigator.switchFormTab(newForm.formInstanceID);

            //ustawiamy nazwę formy na toolbarze
            if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
                this.navigator.showFormName(newForm.caption || newForm.label || " ");
        }

        if (targetObject == SWindowForm) {
            this.windowForms.push(newForm);
        }

        //rejestrujemy konieczność obsługi przycisku wstecz, bo może posłuzy on do zamknięcia nowo otwartej formy
        App.historyHelper.captureBackButton();
    },

    getPosition: function (collection, form) {
        for (var i = 0; i < collection.length; i++) {
            if (collection[i].formInstanceID == form.formInstanceID) {
                return i;
            }
        }

        console.info("Nie znaleziono formy w podanej kolekcji: " + collection);
        return -1;
    },

    findForm: function (id) {
        var form;
        var result = {}
        result.form;
        result.type;

        //szukamy formy na stosie
        form = $.grep(this.stackForms, function (e) { return (e.formInstanceID == id) });
        if (form.length > 0) {
            result.form = form[0];
            result.type = "MDI";
        }

        //jeśli nie znaleziono na stosie formy to szukamy w okienkach
        if (form.length < 1) {
            form = $.grep(this.windowForms, function (e) { return (e.formInstanceID == id) });

            if (form.length > 0) {
                result.form = form[0];
                result.type = "WINDOW";
            }
        }

        //jesli nie znaleziono formy na stosie i w okienkach to szukamy w słownikach wszystkich form
        if (form.length < 1) {
            form = this.findDictForm(id);
            if (form.length > 0) {
                result.form = form[0];
                result.type = "DICT";
            }
        }

        if (form.length > 1) {
            console.error("Znaleziono więcej niż jedną formę o id = " + id + ". Została zwrócona pierwsza znaleziona forma w funkcji findForm");
        }

        return result;
    },

    findDictForm: function(id) {
        var result = [];

        //szukamy słownika w formach mdi
        result = this.findDictFormInCollection(this.stackForms, id);

        //szukamy słownika w formach okienek
        if (result.length < 1) {
            result = this.findDictFormInCollection(this.windowForms, id);
        }

        if (result.length > 1) {
            console.error("Znaleziono więcej niż jeden słownik o id: " + id + ". Została zwrócony pierwszy znaleziony słownik w funkcji findDictForm");
        }

        return result;
    },

    findDictFormInCollection: function (colection, id) {
        var result = [];
        var len = colection.length;

        for (var i = 0 ; i < len ; i++) {
            var dict = [];
            var form = colection[i];
            dict = $.grep(form.dictForms, function (e) { return (e.formInstanceID == id) });

            $.merge(result, dict)
        }

        return result;
    },

    showWindowFromStack: function (form) {
        var index = this.getPosition(this.stackForms, form);
        if (index >= 0) {
            //uruchamiamy jakąś formę z środka stosu
            if (index < this.stackForms.length - 1) {
                this.stackForms.splice(index, 1);
                this.stackForms.push(form);
                //ukrywamy cssem aby nie robic relayoutu
                //form.objectDOM.css("display", "none");
                this.currentMDIForm.detach();
                //this.currentMDIForm.stackCurrentForm = false;
            }
            
            this.currentMDIForm = form;
            this.currentMDIForm.stackCurrentForm = true;
            this.currentMDIForm.attach();
            this.layoutCurrentForm();
            App.gui.navigator.switchFormTab(form.formInstanceID); //ustawiamy aktywną zakładkę
        }
        else {
            console.error("Nie znaleziono formy na stosie.");
        }

        //ustawiamy nazwę formy na toolbarze
        if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
            this.navigator.showFormName(form.caption || form.label || " ");
    },

    closeForm: function (id) {
        var obj = this.findForm(id);
        var form = obj.form;
        var type = obj.type;

        if (form) {
            form.close();

            if (type == "MDI") {
                //usuwanie formy z kolekcji
                var index = this.getPosition(this.stackForms, form);
                var len = this.stackForms.length;
                if (index >= 0) {
                    //usuwamy z stosu formę
                    App.gui.navigator.removeFormTab(form.formInstanceID); //usuwamy zakłądkę formy, którą zamykamy
                    this.stackForms.splice(index, 1);

                    //sprawdzamy czy zamykana forma jest pierwsza na stosie
                    if (index == len - 1 && this.stackForms.length > 0) {
                        //wyświetlamy kolejną formę z stosu
                        var nextForm = this.stackForms[len - 2];
                        this.showWindowFromStack(nextForm);
                    }
                }
                if (this.stackForms.length == 0) {
                  var middlePaneHeight = $("#middle-pane").height() || 0;
                  var footerHeight = $('.footer').height() || 0;
                  var margin_top = middlePaneHeight - footerHeight;
                    $('.footer').css('margin-top', margin_top);
                }
            }
            else if (type == "DICT") {
                //Jeżeli została znaleziona forma a jej pozycja jest -1 oznacza to że jest to słownik
                if (form.dictParentForm) {
                    var index = this.getPosition(form.dictParentForm.dictForms, form);
                    $(obj.form.dictElement).data("SObject").tooltipVisible = true;
                    if (index >= 0) {
                        form.dictParentForm.dictForms.splice(index, 1);
                    }

                }
                else {
                    console.error("Nie znaleziono formy dla której został otworzony słownik");
                }
            }
            else if (type == "WINDOW") {
                var index = this.getPosition(this.windowForms, form);

                if (index >= 0) {
                    this.windowForms.splice(index, 1);
                }
                else {
                    console.error("Nie znaleziono formy dla danego okienka");
                }
            }
            else {
                console.error("Nieznany typ formy");
            }

            if (this.stackForms.length == 0) {
                if (Object.getPrototypeOf(this.navigator) !== SLooseNavigator)
                    this.navigator.showFormName(" ");

                this.navigator.hideBackButton();
                this.currentMDIForm = undefined;
            }
            //zmieniła się bieżąca forma, więc aktualizujemy wyszukiwarkę w nawigatorze
            this.setSearchVisibility();
        } else {
            console.error("Nie znaleziono formy o id:" + id + " podczas próby zamknięcia okna");
        }
    },

    /*
    * Metoda zwraca okno, które obecnie jest najbardziej na wierzchu
    */
    getTopmostForm: function () {
      var self = this;
      var minzIndex = 0;
      var topmostForm = undefined;
      //weryfikuje czy mam uruchomione okna typu message
      //jesli tak to nic nie zwracam
      if($('.message').length > 0)
        return undefined;
      //najpierw szukamy otwartej formy słownika
      if(!topmostForm) {
          var anydictform = $('.SDICTFORM');
          if (anydictform.length > 0) {
            topmostForm = $(anydictform[0]).data("SObject");
          }
      }
      //jeśli nie ma słownika to szukamy okna modalnego o nawyższym zindex
      if (!topmostForm) {
        $('.k-window').each(function () {
          if (this.style.zIndex > minzIndex) {
            minzIndex = this.style.zIndex;
            topmostForm = $($(this).find(".SWINDOWFORM")[0]).data("SObject");
          }
        });
      }
      if (topmostForm)
        return topmostForm;
      //jeśli nic nie znaleziono to zwracamy bieżącą formę MDI
      return this.currentMDIForm;
    },

    back: function () {
        //zamykamy wszystkie słowniki dla danej formy
        if (this.currentMDIForm) {
            //<BS79316 to forma powinna decydować co się ma dziać przy zamknięciu
            //a to się wykona jak serwer odeśle polecenie zamknięcia formy
            //</BS79316>
            App.communication.BP.closeForm(this.currentMDIForm.formInstanceID);
        }
    },

    showForm: function (id, uuid, formXml, formUUID, messageId) {
        // obsługa callbacka dla nawigatora do zrobienia - sprawdzic czy potrzebna
        App.gui.resizeWrapper();
        var formStyle = $(formXml).attr("FS");
        var mode = $(formXml).attr("M");
        var obj = this.findForm(id)
        var form = obj.form;
        var type = obj.type;
        var isProfileNameVisible = this.stackForms.length == 0;

        //sprawdzam czy istnieje juz taka forma
        if (!form) {
            //tworzymy nową formę
            this.addWindowToStack(id, formStyle, formXml, formUUID, messageId, mode);
        }
        else {
            //uruchamiamy już istniejącą formę
            if (type == "MDI") {
                this.showWindowFromStack(form);
            }

            if (type == "WINDOW") {
                //czy potrzeba to opragromywać - zastanowić się
            }
        }

        if (isProfileNameVisible && this.stackForms.length > 0) {
            if (App.gui.mobileVersion) {
                this.navigator.showBackButton();
            } else {
                this.navigator.hideProfileName();
            }
        }
    },

    checkIfAppended: function (id) {
        return jQuery("[fiid='" + id + "']").length > 0;
    },

    /**
     * Funkcja zamyka wszyskie formy MDI i wolne
     * @param {bool} forceclose - jeżeli ustawiony na true to zamykamy okna bez pytania o to uzytkownika
     */
    closeAllForms: function (forceclose) {
        //tu jest ok, bo chcemy zamykać te formy tylko po stronie serwera
        //bo w kliencie po przeładowaniu i tak ich nie ma
        var clone = this.stackForms.slice();
        var len = clone.length;

        for (var i = 0; i < len; i++) {
            var form = clone[i];
            App.communication.BP.closeForm(form.formInstanceID, forceclose);
        }

       clone = this.windowForms.slice();
       len = clone.length;

        for (var i = 0; i < len; i++) {
            var form = clone[i];
            App.communication.BP.closeForm(form.formInstanceID, forceclose);
        }

        this.currentMDIForm = undefined;
        this.setSearchVisibility();
    },

    layoutCurrentForm: function () {
      //zmieniono szerokosc nawigatora wiec bezwzglednie layoutujemy widoczną formę MDI
        if (this.currentMDIForm) {
            this.currentMDIForm.refreshLayout();
        }
    },

    prepareAllFormsFromCollection: function(collection) {
        var formsAmount = collection.length;

        for (var i = 0; i < formsAmount; i++) {
            var form = collection[i];
            var dictformsAmount = form.dictForms.length;

            for (var j = 0; j < dictformsAmount; j++) {
                var dictform = form.dictForms[j];
                dictform.needLayout = true;
            }

            form.needLayout = true;
        }
    },

    prepareAllFormsForReLayout: function () {
        this.prepareAllFormsFromCollection(this.stackForms);
        this.prepareAllFormsFromCollection(this.windowForms);
    },

    //sterowanie widoczności przycisku wyszukiwania na nawigatorze
    setSearchVisibility: function () {
      var topmostForm = this.getTopmostForm();
      if (topmostForm) {
            var mainDataset = topmostForm.getMainDataset();

            if (mainDataset && mainDataset.hasPresenters()) {
                mainDataset.restorePreviousSearch();
            } else {
                this.navigator.disableSearch();
            }
        } else {
            this.navigator.disableSearch();
        }
    },

    /**
     * Funkcja wyświetla formę MDI o zadanym ID instancji
     * @param {string} formInstanceID - ID instacji formy którą chcemy wyświetlić
     */
    showMDIFormWithInstanceID: function (formInstanceID) {
        var form = this.findForm(formInstanceID);
        if (form.type == "MDI") {
            this.showWindowFromStack(form.form);
            this.setSearchVisibility();
          }
    }
}


'use strict'
//historyHelper - obiekt pozwalajacy na zarzadzanie historia

var HistoryHelper = {
    initialized: false,
    backButtonCaptured: false,
    
    /**
    * Funkcja INIT inicjujaca obiekt oraz nadpisujaca zdarzenie zmiany historii przegladania
    */
    init: function(){
      if (!this.initialized) {
        //Nadpisanie zdarzenia wlasna funkcja do pobierania ze stosu. Obsluga HistoryAPI
        window.onpopstate = this.popstate;
        this.initialized = true;
      }
    },

    /**
    * Funkcja przejmuje przycisk "Wstecz" przeglądarki na jego jednorazowe naciśnięcie
    */
    captureBackButton: function() {
      // Dodaje wpis aby pokazac przycisk wstecz    
      if (!this.backButtonCaptured) {
        //window.history.pushState(0, null, window.location.origin);
        window.history.pushState(NaN,NaN);
        this.backButtonCaptured = true;
      }
    },

    /**
    * Funkcja wyszukuje okna typu message i zwraca obiekt kendoWindow
    */
    searchMessageWindow: function(){
        var kMessageForm = undefined;
        kMessageForm = $('.message').data("kendoWindow");
        return kMessageForm
    },

    /**
    * Funkcja wywoływana na event zarządzania historią przeglądania. Back, Forward
    */
    popstate: function (event) {
      //zaznaczamy, że przycisk "Wstecz" już nie jest przejęty przez tą funkcję
      App.historyHelper.backButtonCaptured = false;
      //szukamy formy MDI
      var form = FormStack.getTopmostForm();
      if(form != null) {
        App.communication.BP.closeForm(form.formInstanceID);
      } else {
        // gdy nie znalazlem formy to sprawdz czy mam message aby go pokazac
        var kMessageForm = HistoryHelper.searchMessageWindow();
        // jesli istnieje message to pokaz go na pierwszy plan
        if (kMessageForm != null) {
          kMessageForm.toFront();
        } else {
          // wszystkie okna zostały już wcześniej pozamykane, a znów naciśnięto "Wstecz"
          App.gui.showBalloonHint("Naciśnij ponownie 'Wstecz' aby wyjść");
          //tutaj już nie robimy captureBackButton, aby kolejne "Wstecz" zamknęło przeglądarkę
          return;
        }
      }
      //przejmujemy "Wstecz" na kolejne naciśnięcie
      App.historyHelper.captureBackButton();
    }
}
'use strict';

var KendoFixes = {
    //w kendo Q1 2015 jest babol (Q2 2014 w też był) w combo
    //jeżeli w combo wyświetlimy jako pole tekstowe pole klucza (koniecznie musi być typu int),
    //czyli mamy obiekt O pole P w relacji do obiektu O' po polu O'.REF.
    //obiekt O' ma też pole tekstowe O'.TEXT
    //i na formę O wystawiamy pole P jako dwa combo, jedno pokazujące O'.REF jako WARTOŚĆ
    //drugie pokazujące O'.TEXT jako wartość
    //to przy zmianie tej wartości w pierwszym combo, w drugim combo pojawi się wartość klucza wpisana jako WARTOŚĆ
    //to źle zostanie ustawiony selectedIndex przez co funkcja
    //pobiera złą wartość, która jest przekazywana do kontrolek przez model
    //
    //inaczej
    // 1 - aaa
    // 2 - bbb
    //w pierwszym combo po REF zmieniasz wybór z 1 na 2
    //w drugim combo zamiast 'bbb' widać 2
    //wszystko przez porównanie w komentarzu poniżej
    kendoComboBoxText: function (text) {
        text = text === null ? '' : text;
        var that = this;
        var input = that.input[0];
        var ignoreCase = that.options.ignoreCase;
        var loweredText = text;
        var dataItem;
        var value;
        if (text === undefined) {
            return input.value;
        }
        if (that.options.autoBind === false && !that.listView.bound()) {
            that._setText(text);
            return;
        }
        dataItem = that.dataItem();
        if (dataItem && that._text(dataItem) == text) { //tutaj było === text
            value = that._value(dataItem);
            if (value === kendo.ui.List.unifyType(that._old, typeof value)) {
                that._triggerCascade();
                return;
            }
        }
        if (ignoreCase && !that.listView.value().length) {
            loweredText = loweredText.toLowerCase();
        }
        that._select(function (data) {
            data = that._text(data);
            if (ignoreCase && !that.listView.value().length) {
                data = (data + '').toLowerCase();
            }
            return data === loweredText;
        }).done(function () {
            if (that.selectedIndex < 0) {
                input.value = text;
                if (that.options.syncValueAndText) {
                    that._accessor(text);
                }
                that._triggerCascade();
            }
            that._prev = input.value;
        });

        //funkcja wzięta z obiektu List
        function unifyType(value, type) {
            if (value !== undefined && value !== '' && value !== null) {
                if (type === 'boolean') {
                    value = Boolean(value);
                } else if (type === 'number') {
                    value = Number(value);
                } else if (type === 'string') {
                    value = value.toString();
                }
            }
            return value;
        }
    },

    //Nadpisujemy funkcję kendo aby sortować elemnty po stronie klienta
    //gdy mamy włączone grupowanie
    kendoGridDataSourceProcess: function (data, e) {
        var that = this,
            options = {},
            result;

        if (that.options.serverPaging !== true) {
            options.skip = that._skip;
            options.take = that._take || that._pageSize;

            if (options.skip === undefined && that._page !== undefined && that._pageSize !== undefined) {
                options.skip = (that._page - 1) * that._pageSize;
            }
        }

        if (that.options.serverSorting !== true) {
            options.sort = that._sort;
        }

        if (that.options.serverFiltering !== true) {
            options.filter = that._filter;
        }

        if (that.options.serverGrouping !== true) {
            options.group = that._group;
        }

        if (that.options.serverAggregates !== true) {
            options.aggregate = that._aggregate;
            that._aggregateResult = that._calculateAggregates(data, options);
        }

        result = that._queryProcess(data, options);

        that.view(result.data);

        if (result.total !== undefined && !that.options.serverFiltering) {
            that._total = result.total;
        }

        e = e || {};

        e.items = e.items || that._view;

        //Domyślnie było that.trigger(CHANGE, e);
        //trzeba uważać przy aktualizacji czy zmienna
        //CHANGE nie zmieniła wartości
        that.trigger("change", e);
    },

    //Kiedy klikamy po gridzie czasami serwer w odpoweidzi wysyła nam komunikaty, które powodują
    //odświeżenie formy. Skutkuje to tym że jest robiony layout kontrolki grid który wywołuje
    //funkcję refresh na kednoGridzie. Wyniku tego rekord który klikneliśmy ustawia się na dole
    //kontrolki co daje efekt skakania. Aby tego problemu się pozbyć nadpisaliśmy funkcję _scrollCurrent
    //kendoGrida dodając flagę od której zależy czy będzie wykonywany kod odpowiedzialny za
    //scrollowanie grida.

    //Ponadto na stałe wykomentowany został kod odpowiedzialny za scrollowanie do aktualnej komórki (w poziomie).
    //Ponieważ gdy komórka wystawała poza grida, to kliknięcie na nią powodowało przesunięcie poziomego scrolla.
   	kendoGridScrollCurrent: function () {
		var current = this._current;
		var scrollable = this.options.scrollable;
		if (!current || !scrollable || this.disableAutoScroll) { // dodanie flagi disableAutoScroll
			return;
		}
		var row = current.parent();
		var tableContainer = row.closest('table').parent();
		var isInLockedContainer = tableContainer.is('.k-grid-content-locked,.k-grid-header-locked');
		var isInContent = tableContainer.is('.k-grid-content-locked,.k-grid-content,.k-virtual-scrollable-wrap');
		var scrollableContainer = $(this.content).find('>.k-virtual-scrollable-wrap').addBack().last()[0];
		if (isInContent) {
			if (scrollable.virtual) {
				var rowIndex = Math.max(inArray(row[0], this._items(row.parent())), 0);
				this._rowVirtualIndex = this.virtualScrollable.itemIndex(rowIndex);
				this.virtualScrollable.scrollIntoView(row);
			} else {
				this._scrollTo(this._relatedRow(row)[0], scrollableContainer);
			}
		}
		if (this.lockedContent) {
			this.lockedContent[0].scrollTop = scrollableContainer.scrollTop;
		}
		//wykomentowanie kodu odpowiedzialnego za scrollowanie do aktualnej komórki (w poziomie)
		//if (!isInLockedContainer) {
		//	this._scrollTo(current[0], scrollableContainer);
		//}
   	},

  positionColumnResizeHandle: function() {
    var that = this,
      indicatorWidth = that.options.columnResizeHandleWidth,
      lockedHead = that.lockedHeader ? that.lockedHeader.find("thead:first") : $();

    that.thead.add(lockedHead).on("mousemove" + ".kendoGrid", "th", function (e) {
      var th = $(this);
      if (th.hasClass("k-group-cell") || th.hasClass("k-hierarchy-cell")) {
        return;
      }
      that._createResizeHandle(th.closest("div"), th);
    });
  },

  //BS138027 - Błąd w kendo przy htmledit tylko do odczytu przy wolnym ładowaniu dziedziny
  //Próba ustawienia nowej wartości w modelu, w momencie gdy jesteśmy przełączeni na inna zakładke 
  //kończy się błędem, bo głębiej w kendo wywołanie kendo.ui.editor.Dom.windowFromDocument(this.document)
  //zwraca undefined. Dlatego w takiej sytuacji lepiej nie wywoływać refreshTools
  kendoEditorValue: function (html) {
	var body = this.body, editorNS = kendo.ui.editor, options = this.options, currentHtml = editorNS.Serializer.domToXhtml(body, options.serialization);
	if (html === undefined) {
	  return currentHtml;
	}
	if (html == currentHtml) {
	  return;
	}
	editorNS.Serializer.htmlToDom(html, body, options.deserialization);
	this.selectionRestorePoint = null;
	this.update();
	if (kendo.ui.editor.Dom.windowFromDocument(this.document)) { //dodany warunek.
	  this.toolbar.refreshTools();
	}
  }
}

kendo.data.binders.customformat = kendo.data.binders.text.extend({
    init: function (element, bindings, options) {
      kendo.data.Binder.fn.init.call(this, element, bindings, options);

      this.customformat = $(element).data("customformat");
    },
    refresh: function () {
        var e = this.element;
        var p = this.bindings.customformat.path;
        var s = this.bindings.customformat.source;
        var val = s[p];
        if(val instanceof Date)
          val = kendo.format(this.customformat, val);
        else val = kendo.format("{0}", val);
        $(e).text(val);
        $(e).val(val);
    }
});
// Wyłączenie rubberband scrolla na safari
/*$(document).bind('touchmove',
function (e) {
    e.preventDefault();
}
);*/

'use strict';

var LocalStorage = {
    init: function () {
        if (typeof (Storage) !== "undefined") {
            return this;
        }
        else {
            console.warn("Twoja przeglądarka nie obsługuje Web Storage.");
            return undefined;
        }
    },

    set: function (key, value) {
        localStorage.setItem(key, value);
    },

    get: function (key) {
        return localStorage.getItem(key);
    },

    remove: function (key) {
        localStorage.removeItem(key);
    },

    setIfNotExist: function (key, value) {
        if (this.get(key) === null)
            this.set(key, value);
    },

    getAllKeys: function () {
        return Object.keys(localStorage);
    },

    getAllValues: function() {
        var allKeys = Object.keys(localStorage);
        var len = allKeys.length;
        var values = [];

        while (i--) {
            values.push(localStorage.getItem(keys[i]));
        }

        return values;
    },

    getValuesForKeysStartingWith: function (phrase) {
        var allKeys = Object.keys(localStorage);
        var len = allKeys.length;
        var selectedKeys = [];
        var values = [];

        while (len--) {
            var key = allKeys[len];
            if (key.indexOf(phrase) == 0)
                selectedKeys.push(key);
        }

        len = selectedKeys.length;
        while (len--) {
            values.push(localStorage.getItem(selectedKeys[len]));
        }

        return values
    }
}
'use strict';

var MessageTextValues = {
    SFile_Select: "Wybierz plik"
}
'use strict';

/**
* Obiekt do obsługi sessionStorage przeglądarki
*/
var RequestLog = {
    init: function () {
        var self = this;
        this.Logs = new Hashmap(function (log) {
            var key = self.getKeyName(log.profile, log.user, log.date);
            return key;
        });

        //Co minute zapis logów do Localstorage
        setInterval(function () {
            self.saveInLocalStorage();
        }, 60 * 1000);

        //co dzień usuwanie starych wpisów z localstorage
        setInterval(function () {
            self.clearLocalStorageFormOldLogs();
        }, 24 * 60 * 60 * 1000);

        return this;
    },

    /**
     * Dodanie nowego wpisu bądź aktualizacja istniejącego wpisu
     */
    add: function (good, error) {
        var keyName = this.getKeyName();
        var log;
        if (this.Logs.containsKey(keyName)) {
            log = this.Logs.getValue(keyName);
            this.updateLog(log, good, error);
        }
        else {
            log = this.createLog();
            this.updateLog(log, good, error);
            this.Logs.add(log);
        }
    },

    /**
     * Zapisanie wszystkich logów do LocalStorage
     */
    saveInLocalStorage: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        for (var i = 0; i < len; i++) {
            var log = logs[i];
            if (!log.stored) {
                log.stored = true;
                var keyName = this.getKeyName(log.profile, log.user, log.date);
                App.localStorage.set(keyName, JSON.stringify(log));
            }
        }
    },

    /**
     * Funkcja uzupełnia obiekt js wpisami z localstorage
     */
    loadFromLocalStorage: function () {
        var logs = App.localStorage.getValuesForKeysStartingWith("log_");
        var len = logs.length;
        var addTimer = false;
        for (var i = 0; i < len; i++) {
            var log = JSON.parse(logs[i]);
            if (log.date == Utils.getCurrentDate() || log.send == true) {
                if (log.send)
                    addTimer = true;

                this.Logs.add(log);
            }
        }

        if (addTimer) {
            this.sendLogsToServer();
        }

        this.clearLocalStorageFormOldLogs();
    },

    /**
     * Funkcja usuwa z localstorage wszystkie logi które nie mają dzisiejszej daty
     * i zostały już wysłane na serwer
     */
    clearLocalStorageFormOldLogs: function () {
        var logs = App.localStorage.getValuesForKeysStartingWith("log_");
        var len = logs.length;
        var currentDate = Utils.getCurrentDate();
        for (var i = 0; i < len; i++) {
            var log = JSON.parse(logs[i]);
            if (log.date != currentDate && !log.send) {
                App.localStorage.remove(this.getKeyName(log.profile, log.user, log.date))
            }
        }
    },

    /**
     * FUnkcja wysyła logi do serwera
     */
    sendLogsToServer: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        var Selected = {
            list: []
        }

        for (var i = 0; i < len; i++) {
            var log = logs[i];
            if (log.send == true) {
                Selected.list.push(log)
            }
        }

        App.communication.BP.requestLogs(JSON.stringify(Selected));


    },

    /**
    *  Pobranie nazwy klucza
    */
    getKeyName: function (profile, user, date) {
        if (typeof (profile) == "undefined" && typeof (user) == "undefined" && typeof (date) == "undefined") {
            profile = App.communication.getProfile();
            user = App.Settings.UserName;
            date = Utils.getCurrentDate();
        }

        return "log_" + date + "_" + user + "_" + profile;
    },

    /**
     * Funkcja tworząca obiekt Loga
     */
    createLog: function () {
        return {
            date : Utils.getCurrentDate(),
            user : App.Settings.UserName,
            profile : App.communication.getProfile(),
            good : 0,
            fail : 0,
            stored : false,
            send : false,
            errors : []
        }
    },

    /**
     * Aktualizacja loga o informacje o kolejnym requescie
     */
    updateLog: function (log, good, error) {
        if (good) {
            log.good++;
        }
        else {
            log.fail++;

            log.send = true;
            if (log.send) {
                this.addTimerSendToServer();
            }

            this.addErrorToLog(log, error);
        }

        log.stored = false;
    },

    /**
     * Funkcja służy do ustawienia wszystkim logom flagi send na false.
     * Wykorzystywana po udanym zapisie logów do pliku.
     */
    markLogsAsSent: function () {
        var logs = this.Logs.getAll();
        var len = logs.length;
        for (var i = 0; i < len; i++) {
            var log = logs[i];
            log.send = false;
        }
    },

    /**
     * Funkcja dodaje text błedu do kolekcji. W przypadku gdy w tekscie znajduje się
     * url to są wycinane z niego parametry, aby skrócić komunikat i uniknąć błędnego
     * zapytania podczas wysyłana logów na serwer.
     */
    addErrorToLog: function (log, error) {
        if (error && error != "") {
            var err = error.split("%3F")[0];
            log.errors.push(err);
        };
    },

    /**
     * Funkcja dodaje timer który cyklicznie próbuje zapisać logi do plików.
     * Po udanym zapisie timer jest usuwany.
     */
    addTimerSendToServer: function () {
        var self = this;
        if (!this.timerSendToServer) {
            this.timerSendToServer = window.setInterval(function () {
                self.sendLogsToServer();
            }, 5 * 60 * 1000);
        }
    }
}

'use strict';


/**
* Obiekt do obsługi sessionStorage przeglądarki
*/
var SessionStorage = {
    /**
    * Funkcja inicializująca obiekt SessionStorage. Sprawdza czy nasza przegąldarka obsługuje Web Storage
    */
    init: function () {
        if (typeof (Storage) !== "undefined") {
            return this;
        }
        else {
            console.warn("Twoja przeglądarka nie obsługuje Web Storage.");
            return undefined;
        }
    },

    /**
    * Zapisuje wartość do sesionStorage
    */
    set: function (key, value) {
        sessionStorage.setItem(key, value);
    },

    /**
    * Pobiera wartość z sessionStorage
    */
    get: function (key) {
        return sessionStorage.getItem(key);
    },

    /**
    * Pobieranie wartości bool z storage
    */
    getBool: function (key) {
        var result = this.get(key);

        if (!result || result == "false")
            return false;
        else
            return true;
    }
}
/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';



var Utils = {
    showLoader: function () {
        $("body").css("cursor", "progress");
        $("#loader").show();
    },

    showProgress: function (fn, async) {
        if (async) {
            //App.gui.resizeWrapper();
            var ldr = $("#loader");

            //var navHeight = $("div#SNAVIGATOR").outerHeight();
            /*var navHeight = $("#menu-toggle").outerHeight();
            var htmlHeight = $("html").height();
            var htmlWidth = $("html").width();
            ldr.css({ "position": "fixed", "top": "-" + navHeight + "px" });
            ldr.width(htmlWidth);
            ldr.height(htmlHeight + navHeight);*/
            $("body").css("cursor", "progress");
            $("#loader").fadeIn(50)
            .queue(function () {
                fn();
                $(this).dequeue();
            });
        } else {
            fn();
        }

        return async;
    },

    hideProgress: function () {
        $("#loader").hide();
        $("body").css("cursor", "default");
    },

    toFName: function (name) {
        if (name != App.Settings.ODataComplexKeyParameterName && name != App.Settings.ODataContextSearchParameterName && name) {
            if (name.indexOf("^") == -1) {
                name = App.Settings.ODataOrginalFieldsPrefix + name;
            }
            else {
                name = App.Settings.ODataDictFieldsPrefix + name.replace("_", "__").replace("^", "_");
            }

        }
        return name;
    },

    transformName: function (name) {
        if (name) {
            var result = [];
            var previousIsFloor = false;
            var len = name.length;

            for (var i = 0; i < len; i++) {
                var c = name[i];
                if (c != "_") {
                    if (previousIsFloor) {
                        previousIsFloor = false;
                        result.push("^");
                    }

                    result.push(c);
                }
                else {
                    if (!previousIsFloor)
                        previousIsFloor = true;
                    else {
                        previousIsFloor = false;
                        result.push("_");
                    }
                }
            }
        }
        return result.join("");
    },

    extend: function (o1, o2) {
        var copy = Object.create(o1);
        var copy2 = Object.create(o2);
        var r = $.extend(copy, copy2);
        if (!r.__super)
            r.__super = [];
        r.__super.push({ obj: o2, base: o1 });
        r.__base = function (object) {
            if (typeof object === 'undefined') {
                return r.__super[0].base;
            }
            var len = r.__super.length;
            for (var i = 0; i < len; i++) {
                var item = r.__super[i];
                if (item.obj === object) {
                    return item.base;
                }
            }
            throw { messge: 'base not found', object: object };
        };
        copy.__parent = function (object) {
            if (typeof object === 'undefined') {
                return r.__super[0].obj;
            }
            var len = r.__super.length;
            for (var i = 0; i < len; i++) {
                var item = r.__super[i];
                if (item.base === object) {
                    return item.obj;
                }
            }
            throw { messge: 'base not found', base: object };
        };
        return r;
    },

    iconMap: {
        "ICON_1": "sl-check-box-2",
        "ICON_2": "sl-remove-2",
        "ICON_3": "sl-remove-box",
        "ICON_4": "sl-check-2",
        "ICON_5": "sl-add-1",
        "ICON_6": "sl-bin-1",
        "ICON_7": "sl-pencil-2",
        "ICON_8": "sl-search",
        "ICON_9": "sl-glasses-round-2",
        "ICON_10": "sl-print-text",
        "ICON_13": "fa fa-bolt",
        "ICON_53": "sl-infomation-circle",
        "MI_1": "sl-file-new-1",
        "MI_2": "fa fa-folder-open-o",
        "MI_3": "sl-floppy-disk",
        "MI_4": "fa fa-files-o",
        "MI_5": "sl-scissors-1",
        "MI_6": "sl-arrow-down-12",
        "MI_7": "sl-bubble-chat-question-1",
        "MI_8": "sl-copy-paste",
        "MI_9": "sl-print-text",
        "MI_AKCJE": "sl-envelope-3",
        "MI_ARCHCEN": "fa fa-tags",
        "MI_ARCHTOW": "fa fa-archive",
        "MI_ARROWRIGHT": "fa fa-arrow-right", //"sl-arrow-right-1",
        "MI_ARROWLEFT": "fa fa-arrow-left",//  "sl-arrow-left-1",
        "MI_ASORTYMENT": "sl-boxes-2",
        "MI_BANK": "sl-bank-2",
        "MI_BANKS": "sl-bank-2",
        "MI_BELL": "fa fa-bell-o",
        "MI_CENNIKI": "sl-price-tag",
        "MI_CHART": "sl-graph-bar-2",
        "MI_CLO": "sl-sign-no-entry",
        "MI_CLOSE": "sl-close-badge",
        "MI_CRM": "sl-id-card-double",
        "MI_CUBE": "fa fa-cube",
        "MI_DASHBOARD": "sl-window-tabs-3",
        "MI_DATABASE": "fa fa-database",
        "MI_DOC": "sl-new-document-text",//MS: do tego miejsca wszystkie są zrobione

        "MI_DOSTAWA": "sl-transfer-3",
        "MI_DOWN": "sl-arrow-down-10",
        "MI_EDITUNDO": "fa fa-undo",
        "MI_EDITALIGNLEFT": "sl-align-left",
        "MI_EDITBULLETEDLIST": "sl-list-bullets-1",
        "MI_EDITCHECK": "sl-check-box-1",
        "MI_EDITNUMBEREDLIST": "sl-list-number",
        "MI_EDITINDENTRIGHT": "sl-indent-left",
        "MI_EMAILSENT": "sl-email-send-3",
        "MI_EMAILOUT": "sl-email-read-2",
        "MI_EXCLAMATION": "fa fa-exclamation-triangle importantColor",
        "MI_FAK1": "sl-file-cash-2",
        "MI_FASTENER": "sl-paperclip-2",
        "MI_FKAMORTPLAN": "sl-calendar-timeout",
        "MI_FKBOOK1": "sl-book-open-4",
        "MI_FKBOOK2": "sl-book-open-2",
        "MI_GRUPYKLI": "sl-account-group-2",
        "MI_HISTORIA": "sl-cassette-tape",
        "MI_INFORMATION": "sl-bubble-chat-information-2",
        "MI_KONTAKTY": "sl-phone-call-2",
        "MI_KORESPOND": "sl-email-2",
        "MI_MENU": "fa fa-ellipsis-v",
        "MI_MENU2": "fa fa-bars",
        "MI_MESSAGE": "sl-bubble-chat-information-2",
        "MI_NARZEDZIA": "sl-wrench",
        "MI_NIEOBECN": "sl-calendar-close-1",
        "MI_NOTATKA": "sl-file-new-1",
        "MI_OKRES": "sl-calendar-1",
        "MI_OPERATOR": "sl-man",
        "MI_OUTSIDE": "sl-logout-1",
        "MI_PADLOCK": "sl-lock-close-1",
        "MI_PKOSOBY": "sl-id-card-3",
        "MI_PUZZLE": "fa fa-puzzle-piece",
        "MI_QUESTION": "sl-badge-question",
        "MI_PROJEKTY": "fa fa-clipboard",
        "MI_REDX": "sl-remove-2 veryImportantColor",
        "MI_REFRESH": "sl-synchronize-3",
        "MI_REPLIK": "sl-syncing",
        "MI_ROZRACH": "sl-money-bag-dollar",
        "MI_SETTINGS": "sl-checklist",
        "MI_SHORTAGE": "sl-computer-bug-1",
        "MI_SPRAWY": "sl-hand-bag-3",
        "MI_STOP": "fa fa-exclamation-circle veryImportantColor",
        "MI_TOOLS": "sl-tools-wrench-screwdriver",
        "MI_TOWARY": "sl-box-3",
        "MI_UP": "sl-arrow-up-10",
        "MI_USERS": "sl-person-2",
        "MI_WFTIME": "sl-synchronize-time",
        "MI_WINDOWS": "fa fa-window-restore",
        "MI_WORLD": "sl-globe-2",
        "MI_WWW": "sl-network-forward",
        "MI_ZADANIA": "sl-file-checklist",
        "MI_ZAM1": "sl-file-module-1"
    },

    getSpriteClasses: function (iconName, enabled, dontReserveSpace, showEmptyPlace) {
        //sprawdzamy czy jest mapowanie na ikonki zewnetrzne
        if (this.iconMap[iconName] != undefined) return this.iconMap[iconName];

        //sprawdzamy czy pobieramy duże ikonki
        var bigIcon = this.checkIfUseStyleSheet("tablet");
        var createClass = true;
        var bigIconNameExists = false;

        //ustalamy jaką ikonę mamy pobrać
        if (bigIcon && App.iconHashmap.containsKey(iconName + "32")) {
            iconName = iconName + "32";
            bigIconNameExists = true;
        }
        else if (App.iconHashmap && !App.iconHashmap.containsKey(iconName)) {
            iconName = "";
            createClass = false
        }
        else {
            //nic nie robimy bo nie trzeba nic dodawac do nazwy ikonki
        }

        //towrzymy klase css dla wybranej ikony
        var Class = "k-sprite";
        if (createClass) {
            var name = 'sprite';

            if (typeof enabled == 'undefined')
                enabled = true;

            if (typeof iconName == 'string')
                iconName = iconName.trim();

            if ((!iconName || iconName.length == 0 || iconName == "EMPTY") && dontReserveSpace)
                Class = "";

            if (iconName && iconName.length > 0 && iconName != "EMPTY") {
                var name = 'sprite';
                if (iconName.indexOf('32') >= 0)
                    name = name + '32';
                name += " sprite-" + iconName;
                if (!enabled)
                    name += 'disabled';

                Class = Class + " " + name;
            }
        }
        else {
            Class = "";

            if (!showEmptyPlace) {
                Class = bigIcon ? "empty32" : "k-sprite noneIcon";
            }
            else {
                Class = "k-icon k-i-none";
            }
        }

        //Jeżeli mamy styl tabletowy (bigIcon == true) oraz nie mamy dużej ikonki o podanej nazwie (bigIconNameExists == false)
        //To dodajemy klasę ShowSmallIconForTablet aby mechanizm wyświetlania małych ikon pobierał poprawnego sprite.
        if (bigIcon && !bigIconNameExists) {
            Class += " ShowSmallIconForTablet";
        }

        return Class;
    },

    getSpriteIcon: function (iconName, enabled, spriteCssClasses) {
        var classes = "k-sprite";
        if (!spriteCssClasses) {
            classes = classes + " " + this.getSpriteClasses(iconName, enabled);
        } else {
            classes = spriteCssClasses;
        }
        var $span = $("<span/>");
        $span.addClass(classes);
        return $span;
    },

    getSpriteIconForButton: function (iconName, enabled) {
        var classes = "buttonIcon";
        classes = classes + " " + this.getSpriteClasses(iconName, enabled);
        var $span = $("<span/>");
        $span.addClass(classes);
        return $span;
    },


    getChecked: function (val, val4yes) {
        if (val == val4yes)
            return "checked";
        return "";
    },

    validateValueCheckbox: function (val, val4yes, val4no) {
        if (val != val4yes && val != val4no) {
            console.info("Ustawiona wartość chekboxa nie pasuje do jego wartości")
        }
    },

    createRepositoryFileImgElement: function (beginUrl, repo, file, cssClass) {
      var url = "";
      var hint = "";

      if(file != null)
        url = beginUrl + "&rep=" + repo + "&file=" + file;

      var img = "";
      if (url != "")
        img = "<img src='" + url + "' class='" + cssClass + "'>";
      else
        img = "<div class='" + cssClass + "'></div>";

      return img;
    },

    createRepositoryFilePreview: function (beginUrl, repo, filename, displayfilename, cssClass, clickable) {
      var url = "";
      var hint = "";

      if(filename != null)
        url = beginUrl + "&rep=" + repo + "&file=" + filename;
      if (displayfilename == null)
        displayfilename = filename;
      filename = filename.toLowerCase();

      var img = "";
      if (url != "") {
        if (filename.endsWith('.pdf')) {
          img = "<embed src='" + url + "' class='" + cssClass + "' type='application/pdf'>";
          hint = displayfilename;
        } else if (filename.endsWith('.doc') || filename.endsWith('.docx')
                || filename.endsWith('.xls') || filename.endsWith('.xlsx')
                || filename.endsWith('.ppt') || filename.endsWith('.pptx'))
        {
          img = "";
          hint = displayfilename +" &nbsp;<span style='font-size:smaller;'>(kliknij, aby pobrać dokument)</span>";
        } else if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')
                || filename.endsWith('.png') || filename.endsWith('.gif')
                || filename.endsWith('.bmp')) {
          img = "<img src='" + url + "' class='" + cssClass + "'>";
          hint = displayfilename;
        } else {
          img = "";
          hint = displayfilename;
        }
      } else
        img = "<div class='" + cssClass + "'></div>";

      if (url != "" && clickable)
        img = "<a target='blank' href='" + url + "'>" + hint + img + "</a>";

      return img;
    },

    createBinaryFileImgElement: function (beginUrl, tablename, tablefield, ref, file, cssClass, clickable) {
        var url = "";

        if (ref != null && file != null) {
            var filename = createNewFileName(ref, file);
            var url = beginUrl + "&tn=" + tablename + "&tf=" + tablefield + "&file=" + filename;
        }

        var img = "";
        if (url != "")
            img = "<img src='" + url + "' class='" + cssClass + "'>";
        else
            img = "<div class='" + cssClass + "'></div>";

	if(url != "" && clickable)
		img = "<a target='blank' href='"+url+"'>"+img+"</a>"

        return img;

        function createNewFileName(ref, file) {
            var exstension = "";
            var name = "0000000000";

            if (file)
                exstension = "." + file.split(".")[1];
            if (ref)
                name = name.substring(ref.length) + ref;

            return name + exstension;
        }
    },

    getListStyleSheets: function () {
        //pobieramy liste wszyskich plikow css obecnie podpiętych do aplikacji
        App.listStyleSheets = [];
        var sheets = document.styleSheets
        var len = document.styleSheets.length;

        for (var i = 0; i < len; i++) {
            var sheet = sheets[i];
            if (sheet.href) {
                App.listStyleSheets.push(sheet.href);
            }
        }
    },

    checkIfUseStyleSheet: function (name) {
        //sprawdzamy czy obecnie jest uzywany podany styl
        var len = App.listStyleSheets ? App.listStyleSheets.length : 0;
        var result = false;

        for (var i = 0; i < len; i++) {
            var sheet = App.listStyleSheets[i];
            if (sheet.indexOf(name) >= 0) {
                result = true;
                break;
            }
        }

        return result;
    },

    checkIfBrowserIsSupported: function () {
        // Wspieramy Chrome, Firefox i Safari
        if (navigator.userAgent.indexOf("Chrome") != -1 || navigator.userAgent.indexOf("Safari") != -1 || navigator.userAgent.indexOf("Firefox") != -1) {
            return;
        }
        // Przy każdej innej przegladarce krzyczymy alertem
        alert('Aplikacja może działać nieprawidłowo, gdyż używasz niewłaściwej przeglądarki.\nZalecana przeglądarka to Google Chrome.');
        window.location.replace('about:blank');
    },

    createIconHashMap: function () {
        App.iconHashmap = new Hashmap(function (component) {
            return component;
        });

        var url, separator;
        if (App.Settings.DevMode == "yes") {
            url = App.Settings.CssVirtualPath + "\\sprite.css";
            separator = "\n";
        } else {
            url = App.Settings.CssVirtualPath + "\\sprite.min.css";
            separator = ".";
        }

        var stringData = $.ajax({
            url: url,//"styles\\sprite.css",
            async: false  //spróbować zrobić jako true bo nie synchronicznie nie wiadomo i le bedzie to zajmowalo
        }).responseText;

        var lines = stringData.split(separator);
        var len = lines.length;

        for (var i = 1; i < len; i++) {
            var line = lines[i];
            if (App.Settings.DevMode == "no")
                line = "." + line;
            if (line.indexOf(".sprite-") >= 0) {
                var iconName = line.split("{")[0].replace(".sprite-", "").replace("disabled", "");
                App.iconHashmap.add(iconName)
            }
        }
    },

    //testowe - nalezy poprawic i to gruntownie
    getAllIconNames: function () {
        var names = [];

        var url, separator;
        if (App.Settings.DevMode == "yes") {
            url = App.Settings.CssVirtualPath + "\\sprite.css";
            separator = "\n";
        } else {
            url = App.Settings.CssVirtualPath + "\\sprite.min.css";
            separator = ".";
        }

        var stringData = $.ajax({
            url: url,//"styles\\sprite.css",
            async: false
        }).responseText;

        var lines = stringData.split(separator);
        var len = lines.length;

        for (var i = 1; i < len; i++) {
            var line = lines[i];
            if (App.Settings.DevMode == "no")
                line = "." + line;
            //co z tym 32 jesli bedzie icon_32 to sie posypie
            var line = line.split("{")[0].replace(".sprite-", "").replace("disabled", "").replace("32", "");
        }

        return names;

    },


    // Zestaw funkcji do budowania template'ow wierszy.
    rowTemplate: {

        /* Parsuje ciag formatujacy. Zwraca obiekt postaci
           { kolumna: { wartosc: formatowanie } }, np.
           "NAME=*=%TclGreen*" -> { "NAME": { '*': "%TclGreen*" } }
        */
        parseFormatString: function (format) {
            if (!format)
                return {};

            var result = {};
            var rules = format.split(';');
            for (var i = 0; i < rules.length; i++) {
                var rule = rules[i].trim();
                var colRegexp = /[\*\w]+=/;
                var match = colRegexp.exec(rule);
                if (match && match.index == 0) {
                    var field = match[0].substring(0, match[0].length - 1);
                    rule = rule.slice(match[0].length);
                    var options = rule.split('|');
                    result[field] = {};
                    for (var j = 0; j < options.length; j++) {
                        var o = options[j].split('=');
                        var cond = o[0];
                        var then = o[1];
                        result[field][cond] = then;
                    }
                }
            }

            return result;
        },

        /* Formatuje <value> z kolumny <column> zgodnie z ciagiem <formatString>.
           Funkcja <makeCell> opakowuje ja w html wlasciwy dla kontrolki.

           <column> - nazwa pola w bazie danych
           <value>  - nazwa pola w zrodle danych
           (kendo podstawia wartosci przy wykonaniu kodu template)

           przyklad:
           Utils.rowTemplate.formatField("NAME=*=%TclGreen*",
             "NAME", "fNAME", Utils.rowTemplate.grid.makeCell);

        */
        formatField: function (formatString, column, value, makeCell, isTree, firstTreeColumn, cssClasses, iconMode) {
            if (!formatString)
                formatString = '';

            if (iconMode != 'noIcon')
                cssClasses = "";

            var parsed = parseGridValue(formatString, value, iconMode);
            var template = makeCell(parsed, column, isTree, firstTreeColumn, cssClasses);
            return template;

            // jak w SGridDrawCellInfo.cpp (sdas3)
            function parseGridValue(formatStr, value) {
                var css = {};
                var result = "";
                var bitmap = undefined;

                if (iconMode == 'noIcon')
                    value = value.value;
                else {
                    bitmap = value.icon;

                    if (iconMode == 'onlyIcon')
                        value = "";
                    else
                        value = value.value;
                }

                var inpos = 0;
                while (inpos < formatStr.length) {
                    var c = formatStr[inpos];
                    var end;
                    switch (c) {
                        case '%':
                            if (inpos < formatStr.length - 1) {
                                inpos++;
                                c = formatStr[inpos];
                                switch (c) {
                                    case '%': result += '%'; break;
                                    case 'B': css.fontWeight = 'bold'; break;
                                    case 'b': css.fontWeight = 'normal'; break;
                                    case 'D': result += new Date().toISOString().slice(0, 10); break; // format daty?
                                    case 'I': css.fontStyle = 'italic'; break;
                                    case 'i': css.fontStyle = 'normal'; break;
                                    case 'U': css.textDecoration = 'underline'; break;
                                    case 'S': css.textDecoration = 'line-through'; break;
                                    case 'u':
                                    case 's': css.textDecoration = 'none'; break;
                                    case 'T':
                                    case 'C':
                                        end = nextSpecialChar(inpos + 1);
                                        var color = formatStr.slice(inpos + 1, end);
                                        if (color) {
                                            color = color.toLowerCase();
                                            var prefix = color.substring(0, 2);

                                            if (prefix == "cl")
                                                color = Utils.rowTemplate.vclToRGB(color);
                                            else if (prefix == "0x")
                                                color = Utils.rowTemplate.bgrToRgb(color);
                                            else
                                                color = ""; // error...

                                            if (formatStr[inpos] == 'T')
                                                css.color = color;
                                            if (formatStr[inpos] == 'C')
                                                css.backgroundColor = color;
                                        }
                                        inpos = end - 1;
                                        break;
                                }
                            }
                            break;

                        case '*':
                            if (inpos + 1 < formatStr.length && formatStr[inpos + 1] == '*') {
                                result += '*';
                                inpos++;
                            } else {
                                result += value;
                            }
                            break;

                        case '#':
                            if (inpos + 1 < formatStr.length && formatStr[inpos + 1] == '#') {
                                result += '#';
                                inpos++;
                            } else {
                                end = nextSpecialChar(inpos + 1);
                                bitmap = formatStr.slice(inpos + 1, end);
                                inpos = end - 1;
                            }
                            break;

                        default:
                            result += formatStr[inpos];
                            break;
                    }

                    inpos++;
                }

                return { 'value': result, 'css': css, 'bitmap': bitmap };

                function nextSpecialChar(startpos) {
                    var str = formatStr;
                    var i = startpos;
                    while (i < str.length && " %*#".indexOf(str[i]) == -1) i++;
                    return i;
                }
            }
        },

        // Specyficzne dla szablonow kendoGrid.
        grid: {
            makeCell: function (parsed, column, isTree, firstTreeColumn, cssClasses) {
                var span = $('<span class="inner-cell ' + cssClasses + '"/>').html(parsed.value);
                var td = isTree ? $('<span class="inner-cell ' + cssClasses + '" />').css(parsed.css) : $('<td/>').css(parsed.css);

                if (parsed.value) {
                    if (isTree)
                        td.append(parsed.value);
                    else
                        td.append(span);

                    if (parsed.bitmap)
                        span.prepend(Utils.getSpriteIcon(parsed.bitmap, true));
                }
                else {
                    td.append(Utils.getSpriteIcon(parsed.bitmap, true));
                }

                if (column) {
                    td.attr('data-column', column);
                }

                return $('<div/>').append(td).html();
            },

            // (!) Ta funkcja jest wywolywana w SGrid.createTemplateRow
            getCellTemplate: function (format, columnName, value, formatstr, rawDataType, magicKey, isTree, firstTreeColumn, iconMode) {
                var template = "";
                template += '# var td = ' +
                     'Utils.rowTemplate.grid.generateTd(' +
                     format + ', "' + columnName + '", ' + value;
                if (formatstr) {
                    //w js nie działa replace dla wszystkich wystąpien znaków.
                    //Jest to potrzebne ponieważ w template nieparzysta ilość # powodowała błąd
                    template += ',"' + formatstr.split("#").join("\\#") + '"';
                }
                else {
                    template += ', ""';
                }
                template += ', "' + rawDataType + '"';
                template += ', "' + magicKey + '"';
                template += ', ' + isTree;
                template += ', ' + firstTreeColumn;
                template += ', "' + iconMode + '"';
                template += ');';
                template += 'if (td) {# #= td# # } #';

                return template;
            },


            // Generuje HTML pojedynczej komorki na podstawie
            // formatowania i biezacej wartosci.
            generateTd: function (format, key, val, mask, rawDataType, magicKey, isTree, firstTreeColumn, iconMode) {
                var td = "";
                var field;
                var rule;

                // znajdz regule dla odpowiadajacej kolumny
                if (format['*'])
                    field = format['*'];
                else if (format[key])
                    field = format[key];

                // znajdz regule dla danej wartosci pola
                if (field)
                    if (field['*'])
                        rule = field['*'];
                    else if (field[val])
                        rule = field[val];

                // pozbadz sie null-i
                var res = Utils.parseValType(val, rawDataType, mask);

                var cls = res.cls;

                if (isTree && firstTreeColumn) {
                    cls += " inTree";
                }

                val = res.newval;
                val = Utils.replaceLineEndingsInNonHtmlTag(val);
                val = this.generateTdValue(val, magicKey, rawDataType, iconMode);

                if (rule) {
                    td = Utils.rowTemplate.formatField(rule, key, val, this.makeCell, isTree, firstTreeColumn, cls, iconMode); // co tutaj
                } else {
                    if (!isTree) {
                        if (iconMode != "noIcon" && val.icon) {
                            var text = null;
                            if (iconMode == "onlyIcon")
                                text = "";
                            else
                                text = val.value;
                            td = "<td data-column=\"" + key + "\" class=\"" + cls + "\"><div style='text-align:left;'><span class='" + Utils.getSpriteClasses(val.icon, true) + "'></span>" + "<span style='display:inline;' class='inner-cell'>" + text + "</span></div></td>";
                        } else
                            td = "<td data-column=\"" + key + "\" class=\"" + cls + "\"><span class='inner-cell'>" + val.value + "</span></td>";

                    } else {
                        td = "<span data-column=\"" + key + "\" class=\"inner-cell " + cls + "\">" + val.value + '</span>';
                    }
                }
                return td;
            },

            generateTdValue: function (val, magicKey, rawDataType, mode, forGroupHeader) {
                if (val === "" || val == undefined) {
                    val = "&nbsp;"
                }

                val = Utils.setValuFromPickList(magicKey, val);

                if (rawDataType)
                    val.value = Utils.formatDateTimeNumberValue(rawDataType, val.value);

                return !forGroupHeader ? val : val.value;
            },
        },

        // Mapuje kolory VCL na RGB
        vclToRGB: function (color) {
            var vclMapping = {
                claqua: "#00FFFF",
                clblack: "#000000",
                clblue: "#0000FF",
                clcream: "#FFFBF0",
                cldkgray: "#808080",
                clfuchsia: "#FF00FF",
                clgray: "#808080",
                clgreen: "#008000",
                cllime: "#00FF00",
                clltGray: "#C8C8C8",
                clmaroon: "#080000",
                clmedGray: "#A0A0A4",
                clmoneyGreen: "#C0DCC0",
                clnavy: "#000008",
                clolive: "#808000",
                clpurple: "#800080",
                clred: "#FF0000",
                clsilver: "#C8C8C8",
                clskyBlue: "#A6CAF0",
                clteal: "#008080",
                clwhite: "#FFFFFF",
                clyellow: "#FFFF00",
                clnone: "#000000"
            };

            return vclMapping[color.toLowerCase()];
        },

        // Mapuje VCL 0xBBGGRR na #RRGGBB
        bgrToRgb: function (colorHexStr) {
            if (colorHexStr.length != 8)
                return "";

            var hex = colorHexStr.substring(2, colorHexStr.length);
            return '#' +
                hex.substring(4, 6) +
                hex.substring(2, 4) +
                hex.substring(0, 2);
        }
    },

    /**
     * Zwraca true, jeśli kolumna danego typu powinna być formatowana do prawej w gridzie
     */
    formatToRight: function (rawDataType) {
        return rawDataType == 2 || rawDataType == 3 || rawDataType == 7 || rawDataType == 101 || rawDataType == 102;
    },


    parseValType: function (val, rawtype, format) {
        var cls = "";
        //dla typów numeric dodajemy klasę numericInGrid która
        //odpowiada za wyrównywanie wartości do prawej strony
        if (this.formatToRight(rawtype)) {
            cls = "numericInGrid";
        }

        //Parsujemy wartość do danego typu
        if (rawtype == 2 || rawtype == 3) {
            newval = parseInt(val);
        } else
            if (rawtype == 7 || rawtype == 101 || rawtype == 102) {
                newval = parseFloat(val);
            }

        if (newval != val) {
            newval = val;
            cls = "";
        }

        //pozbywamy sie null-i
        var newval = val == null ? "" : newval;

        if (format) {
            newval = kendo.format(format, newval);
        }
        return { newval: newval, cls: cls };
    },

    callActionWithShortKey: function (e, shortKey) {
        var actionID;
        var SObject = App.gui.getFirstSObject(e.currentTarget);

        var actions = SObject.dataset.actions.getAll();
        var len = actions.length;
        for (var i = 0; i < len; i++) {
            var action = actions[i]

            if (action.shortkey == shortKey) {
                actionID = action.actionInstanceID;
            }
        }

        App.gui.communication.BP.callAction(actionID);
    },

    operationsUrlParameters: {
        params: new Hashmap(function (dictElem) {
            return dictElem.key;
        }),

        getURLParameterValue: function (sParam) {

            if (this.params.containsKey(sParam)) {
                return this.params.getValue(sParam).value;
            } else {
                return undefined;
            }
        },

        addParam: function (key, value) {
            var dictElem = {};

            dictElem.key = key;
            dictElem.value = value;
            dictElem.urlParam = key + "=" + value;

            this.params.add(dictElem);
        },

        initParamList: function () {
            if (document.location.search != "") {
                var params = document.location.search.substr(1).split('&');
                var len = params.length;

                for (var i = 0; i < len; i++) {
                    var param = params[i];
                    var tmp = param.split('=');

                    this.addParam(tmp[0], tmp[1]);
                }
            }
        },

        deleteParam: function (key) {
            var dictElem = {};
            dictElem.key = key;

            this.params.remove(dictElem);
        },

        createUrlSearch: function () {
            var params = this.params.getAll();
            var len = params.length;
            var result = "";

            if (len > 0) {
                result = "?";
            }

            for (var i = 0; i < len; i++) {
                var param = params[i];

                result = result + param.urlParam;

                if (i < len - 1) {
                    result = result + "&";
                }
            }

            return result;
        }
    },

    createPickList: function (xml) {
        this.pickItems = new Hashmap(function (pickItem) {
            return pickItem.value;
        });

        var PickItems = $(xml).find("[name='PI']");
        var self = this;
        $(PickItems).each(function () {
            var obj = new Object();
            var tv = $(this).attr("TV");
            var fv = $(this).attr("FV");
            var i = $(this).attr("I");
            obj["text"] = tv || "";
            obj["value"] = fv || "";
            obj["icon"] = i || "";

            self.pickItems.add(obj);
        });

        this.pickList = this.pickItems.getAll();
    },

    setRecordAndCallAction: function (e, actionID, datasetInstanceID, formInstanceID) {
        this.setRecordInGrid(e, datasetInstanceID, formInstanceID);
        App.gui.communication.BP.callAction(actionID, e.id);
    },

    setRecordInGrid: function (e, datasetInstanceID, formInstanceID) {
        var form = App.gui.navigator.formStack.findForm(formInstanceID);
        if (form.form) {
            form = form.form;
            var ds = form.datasets.getValue(datasetInstanceID);
            if (ds) {
                var len = ds.dependentPresenters.length;

                for (var i = 0; i < len; i++) {
                    var presenter = ds.dependentPresenters[i];

                    if (presenter.itemType == "TABLE") {
                        var tr = $(e).parents("tr");
                        if (tr.length == 0) {
                            tr = $(e.target).parents("tr");

                        }

                        if (tr.length > 0) {
                            presenter.immediateDataChange = true;
                            presenter.kendoGrid.select(tr);
                            presenter.immediateDataChange = false;
                        }
                    }
                }
            }
        }
    },

    setValuFromPickList: function (magicKey, val) {
        var newVal = { value: val, icon: null };

        if (magicKey && magicKey != "") {
            var tmp = magicKey.split(";");
            if (tmp.length == 4) {
                var formID = tmp[0];
                var datasetID = tmp[1];
                var gridID = tmp[2]
                var columnID = tmp[3];

                var element = App.gui.navigator.formStack.findForm(formID);
                if (element && element.form) {
                    var ds = element.form.datasets.getValue(datasetID);
                    if (ds) {
                        var grid;

                        var len = ds.dependentPresenters.length;
                        for (var i = 0; i < len; i++) {
                            var presenter = ds.dependentPresenters[i];

                            if (presenter.elementUUID == gridID) {
                                grid = presenter;
                                break;
                            }
                        }

                        if (grid) {
                            var gridColumn;

                            len = grid.columns.length;
                            for (var i = 0; i < len; i++) {
                                var column = grid.columns[i];

                                if (column.uuid == columnID) {
                                    gridColumn = column;
                                    break;
                                }
                            }

                            if (gridColumn) {
                                var value = gridColumn.pickItems.getValue(val);
                                newVal.value = value ? value.text : val;
                                newVal.icon = value ? value.icon : null;


                            }
                            else
                                console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono kolumny grida o uuid: " + columnID);
                        }
                        else
                            console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono grida o UUID '" + gridID + "' w datasecie");
                    }
                    else
                        console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono dataseta o id " + datasetID + " na formie");
                }
                else
                    console.info("Podczas wykonywania funkcji setValuFromPickList() nie znaleziono formy o id: " + formID);
            }
        }

        return newVal;
    },

    getBindingForType: function(type, rawtype, fieldname, dataFormat) {
      var databind = "data-bind='";
      var format = "{0}";
      if(dataFormat)
      {
        format = false;   
      }
      else
      {
          if(rawtype)
          {
            format = this.getFormatForType(rawtype);
          }
          else
          {
            format = this.getFormatForType(rawtype);
          }
      }
      if (format)
      {
        databind += "customformat: "+fieldname;
        databind = "data-customformat='" + format + "' " + databind;
      }
      else
      {
        databind += "value: " + fieldname;
      }



      return databind;
    },

    getFormatForType: function(type) {
      if(type && typeof type === 'string') {
        switch (type.toLowerCase()) {
            case "date":
            case "103":
                return "{0:yyyy-MM-dd}";
            case "time":
            case "104":
                return "{0:T}";
            case "datetime":
            case "8":
                return "{0:G}";
            default:
                return "{0}"
        }
      }
      return "{0}"
    },

    formatDateTimeNumberValue: function (rawDataType, val) {
        switch (rawDataType) {
            case "104":
                if(typeof val == "string") {
                  //aktualnie dla czasu przychodzi z serwera string i to jest lekki problem, bo nieładnie się Formatuje
                  //robimy takie troche dzikie obejścia tematu
                  var dt = new Date("1900-01-01T"+val);
                  if(!isNaN(dt)) {
                    return kendo.format(this.getFormatForType(rawDataType), dt);
                  } else {
                    //jak sie nie uda to spróbuj chociaż pozbyć się .00000 bo to źle wygląda
                    if(typeof val == "string" && val.indexOf('.')>-1) {
                      return val.split('.')[0];
                    }
                  }
                } else if(val instanceof Date) {
                  return kendo.format(this.getFormatForType(rawDataType), val);
                }
                return val;
            case "103":
            case "8":
                val = val == null ? undefined : val;
                return kendo.format(this.getFormatForType(rawDataType), val);
                break;
            case "7":
                //dla typu number przechowanego w stringu, jeśli w modelu przypadkiem jest kropka to zawsze zamieniamy na przecinek
                //kropka może być na mobilnych bo tam używamy natywnego inputa type=number
                if (typeof val == "string")
                    return val.replace(".", ",");
                else if (typeof val == "number")
                    return val.toLocaleString();
                break;
            default:
                return val;
                break;
        }
    },

    /*
    * Funkcja zamienia znaki nowej linii na tagi <br/> w przypadku gdy tekst nie zawiera tagów html. W pp. nie robi nic i zwraca ten sam tekst co na wejsciu.
    */
    replaceLineEndingsInNonHtmlTag: function (text) {
        if (text != undefined) {
            text = text + '';
            var textWithBrs = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
            var textWithNoNewLineCharacters = text.replace(/(?:\r\n|\r|\n)/g, '');
            var textWithNoHtmlTags = $('<div></div>').html(textWithNoNewLineCharacters).text();


            if (textWithNoNewLineCharacters == textWithNoHtmlTags) {//są równe znaczy się nie ma tagów html
                return textWithBrs
            } else return text;
        }
    },

    /*
    * Funkcja wejściowa do obsługi escapowanie html w treści komentarzy
    */
    handleHtmlTagsInText: function (data, ignoreArray) {
        return Utils.handleCodeInText(data, ignoreArray);
    },

    /*
    * Funkcja próbuje znaleźć kod HTML w tresci komentarza i następnie opakować kolejne fragmenty
    */
    handleCodeInText: function (data, ignoreArray) {
        var re = new RegExp('<(?!br)(\\S*)[^>]*>.*<\\/\\1>', 'gs');
        var result = "";

        var matches;
        do {
            matches = re.exec(data);
            if (matches) {
                var matchIdx = data.indexOf(matches[0]);
                var preMatchData = data.substring(0, matchIdx);
                result += preMatchData;
                if (ignoreArray.length > 0) {
                    if(ignoreArray.some(function(i){
                        return matches[0].indexOf(i) > -1;
                    })) {
                        result += matches[0]
                    } else {
                        result += Utils.wrapHtmlInCodeTag(matches[0]);
                    }
                } else {
                    result += Utils.wrapHtmlInCodeTag(matches[0]);
                }

                data = data.substring(matchIdx + matches[0].length);
            } else {
                result += Utils.escapeHtml(data);
            }
        } while (matches);
        return result;
    },

    /*
    * Funkcja escapuje kod HTML i opakowuje w tag code
    */
    wrapHtmlInCodeTag: function(html){
        return "<code>" + html.replace(/<br>/g, "\n").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;') + "</code>";
    },

    escapeHtml: function(text){
        return text.replace(/<br>/g, "\n").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, "<br>")
    },

    /**
    * Funkcja służy do uruchamiania strony podanej w parametrze
    * Zamieniłem assign na replace aby niepotrzebnie nie tworzyć wpisów w historii po logowaniu
    * @param {string} url - url strony którą chcemy otworzyć
    */
    refreshURL: function (url) {
        window.location.replace(url + this.operationsUrlParameters.createUrlSearch());
    },

    getCurrentDate: function () {
        var date = new Date();
        date = kendo.format("{0:"+App.dateFormat+"}", date);
        return date;
    },

    // Funkcja służy do mierzenia elementów zanim zostaną podczepione do drzewa DOM i wyświetlone
    measure: function (element, property) {
        var el = element.clone(false);
        el.css({
            display: 'block',
            visibility: 'hidden',
            position: 'absolute'
        });
        el.appendTo('body');
        var result = el[property]();
        el.remove();
        return result
    },

    //Funkcja kopiuje przekazaną wartość do schowka
    copyToClipboard: function (val) {
        var textArea = document.createElement("textarea");
        textArea.value = val;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try
        {
            var successful = document.execCommand('copy');
            if(!successful)
                console.error("Couldn't copy to clipboard!!!");
        }
        catch (err)
        {
            console.error('Unable copy to clipboard', err);
        }

        document.body.removeChild(textArea);
    },

    generateUUID: function() { // Public Domain/MIT
      var d = new Date().getTime();
      if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
          d += performance.now(); //use high-precision timer if available
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
}

/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';
/**
 * Główny obiekt aplikacji
 * @constructor
 */
function App() {
    this.debug = {
        /*
            coloredBoxes: false,
            layoutDebug:false
        */
        odata: {
            stat: false
        },
        perf: {
            forms: false
        },
        counters: {
            datasets: false
        }
    };
    this.gui = undefined;
    this.communication = undefined;
    this.initialize();
    this.Resources = new Hashmap(function (resource) {
        return resource.Id;
    });
    this.LanguageResources = {
        emptyComboItemText: "(brak)"
    };

    this.kendoFixes = KendoFixes;
    this.localStorage = LocalStorage.init();
    this.requestLog = RequestLog.init();
    this.sessionStorage = SessionStorage.init();
    this.messageTextValues = MessageTextValues;
    this.dateFormat = "yyyy-MM-dd";
    this.UUID = Utils.generateUUID();
}

App.prototype = {
    /*
    * Metoda inicjalizująca instancję aplikacji. Tworzy obiekty współpracujące.
    */
    initialize: function () {
        this.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
        this.communication = new Communication(this, this.profile);

        this.odata = new ODataProxy();


        this.notificationInterval = 60000;

        var self = this;
        window.onresize = function () {
            self.gui.resizing();
        }

        $(document).keydown(function (e) {
          var keyId = e.originalEvent.keyCode || e.keyCode
          if (keyId == 116) {
            return confirm("Wcisnięto F5, które spowoduje odświeżenie strony i zamknięcie wszystkich otwartych okien. Na pewno kontynuować?");
          }
        });

        //Zdarzenie do wykrywania kliknięcia środkowym przyciskiem.
        $(document).on("mousedown", function (e1) {
            $(document).one("mouseup", function (e2) {
                if (e1.which == 2 && e1.target == e2.target) {
    	           var e3 = $.event.fix(e2);
                    e3.type = "middleclick";
                    $(e2.target).trigger(e3)
                }
            });
        });
    },

    /*
     * Metoda włącza mechanizm przejmujący i zarządzający przyciskiem "Wstecz" w przeglądarce
     */
    addhistoryhelper: function () {
        this.historyHelper = HistoryHelper;
        this.historyHelper.init();
    },

    addgui: function () {
        this.gui = new Gui(this);
        this.communication.Gui = this.gui;
    },

    /*
    *Funkcja uruchamia się po wczytaniu strony i uruchamia akcje, które mają się wtedy wykonać.
    */
    postinitialize: function () {
        this.requestLog.loadFromLocalStorage();

        var odataUrl = this.getRelativeODataURL();
        this.odata.init(odataUrl)
        this.gui.getUnit();
        this.communication.BP.getNeosVersion();

        Utils.getListStyleSheets();
        Utils.createIconHashMap();
        this.communication.BP.getResources("SYSTEM.ICONS", "SMALLICON");
        this.gui.navigator = /*App.gui.selectedStructure == "loose" ? Object.create(SLooseNavigator) :*/ Object.create(SNavigator);
        this.action = Utils.operationsUrlParameters.getURLParameterValue("action") || "";
        this.communication.BP.getNavigator();
        this.gui.registerDatavizTheme();
        this.gui.setFooterInCorrectPlace();
        var self = this;

        Utils.hideProgress();
        if (this.Settings.WebsocketUrl)
            this.communication.addWebsocket(this.Settings.WebsocketUrl);

        $("#middle-pane").scroll(function() {
            if (App.gui.navigator.formStack.currentMDIForm) {
                App.gui.navigator.formStack.currentMDIForm.refreshNiceScroll();
            }
        });


        this.pingSession();
    },

    runAction: function() {
      var self = this
      if (this.action != "") {
          setTimeout(function () { self.communication.BP.showActionURL(self.action); }, 50);
      } else {
        this.getNotifications();
      }
    },

    getNotifications: function () {
        var self = this;
        window.setInterval(function () { self.communication.BP.getNotifications() }, this.notificationInterval);
    },

    pingSession: function () {
        var interval = this.Settings["SessionKeepaliveTime"];
        if (interval > 0) {
            var self = this;
            window.setInterval(function () { self.communication.BP.pingSession() }, interval * 1000 * 60);
        }
    },

    getSettings: function (val) {
        var xml = $(val);
        this.Settings = {};
        var keys = $('setting', xml);
        var len = keys.length;
        for (var i = 0; i < len; i++) {
            var item = $(keys[i]);
            var key = item.attr('key');
            var value = item.attr('value');
            this.Settings[key] = value;
        }

        this.Settings.IconVirtualPath = this.Settings.VirtualPath + "/Icon/"
        this.Settings.CssVirtualPath = this.Settings.VirtualPath + "/styles/";
    },

    getRelativeODataURL: function () {
        if (typeof (this.Settings.ODataUrl) == 'string' && this.Settings.ODataUrl.length > 0)
            return this.Settings.ODataUrl;

        var port = parseInt(location.port) + 1;
        if (parseInt(this.Settings.ODataPort) != 0)
            port = this.Settings.ODataPort;

        return location.protocol + '//' + location.hostname + ":" + port + "/";
    },

    getRelativeCurrentURL: function (portDelta) {
      var port = parseInt(location.port);
      portDelta = portDelta || 0;
      var newPort = port + portDelta;
      if(isNaN(newPort))
        return location.protocol + '//' + location.hostname + "/";
      else
        return location.protocol + '//' + location.hostname + ":" + newPort + "/";
    }
}

/// <reference path="../jquery/jquery-1.10.1-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';

function BP(communication) {
    this.communication = communication;
};

BP.prototype = {
    //Funkcje z pliku AppSrvBPFuncs
    showForm: function(objectname, formname, contexts, mode, formstyle, callback) {
        var url = 'BPShowForm?objectname=' + objectname + '&formname=' + formname + '&contexts=' + contexts + '&mode=' + mode + '&formstyle=' + formstyle + '/';
        if (callback)
            this.communication.afterNextFormShown = callback;
        this.communication.sendRequest(url);
    },

    getSettings: function() {
        var url = 'BPGetSettings';
        this.communication.sendRequest(url);
    },

    pingSession: function () {
        var url = 'BPPingSession';
        this.communication.sendRequest(url);
    },

    selectionChanged: function(datasetinstanceid, xml) {
        var url = 'BPSelectionChanged?datasetinstanceid=' + datasetinstanceid + '&xml=' + xml + '/';
        this.communication.sendRequest(url);
    },

    datasetChanged: function(datasetinstanceid, state) {
        var url = 'BPDatasetChanged?datasetinstanceid=' + datasetinstanceid + '&state=' + state + '/';
        this.communication.sendRequest(url);
    },

    dropFiles: function(datasetinstanceid, files) {
        var url = 'BPDropFiles?datasetinstanceid=' + datasetinstanceid + '&files=' + files + '/';
        this.communication.sendRequest(url, undefined, true);
    },

    removeFiles: function(datasetinstanceid, files) {
        var url = 'BPRemoveFiles?datasetinstanceid=' + datasetinstanceid + '&files=' + files + '/';
        this.communication.sendRequest(url);
    },

    fileTransferEnd: function(transferid, xml) {
        var url = 'BPFileTransferEnd?transferid=' + transferid + '&xml=' + xml + '/';
        this.communication.sendRequest(url);
    },

    dataChanged: function(datasetinstanceid, xml) {
        //console.info("Komunikat 'dataChanged' z klienta WWW. datasetinstanceid: '" + datasetinstanceid + "', xml: '" + xml + "'");
        var url = 'BPDataChanged';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                datasetinstanceid: datasetinstanceid,
                xml: xml
            }
        });
    },

    refreshDatasetInstance: function(datasetinstanceid) {
        var url = 'BPRefreshDatasetInstance?datasetinstanceid=' + datasetinstanceid + '/';
        this.communication.sendRequest(url);
    },

    closeForm: function (forminstanceid, forceclose) {
        var extendedinfo = "QUERY"
        if (forceclose)
            extendedinfo = " ";

        var url = 'BPCloseForm?forminstanceid=' + forminstanceid + '&extendedinfo=' + extendedinfo + '/';
        this.communication.sendRequest(url);
    },

    callAction: function(actioninstanceid, elementUUID) {
        var url = 'BPCallAction?actioninstanceid=' + actioninstanceid + '&elementuuid=' + elementUUID +'/';
        this.communication.sendRequest(url, actioninstanceid);

    },

    getNotificationsNow: function() {
        var url = 'BPGetNotificationsNow';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja pobiera nowe wiadmości z kolejki.
    */
    getMessages: function() {
        var url = 'BPGetMessages';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja pobiera notyfikacje z neosa. Używa BPGetNotifications,
    * bo dzięki temu jej wywołanie nie liczy się do czasu bezczynności
    */
    getNotifications: function() {
        var url = 'BPGetNotifications';
        this.communication.sendRequest(url);
    },

    getResources: function(tablename, tableident) {
        var url = 'BPGetResources?objectname=' + tablename + '&resourcetype=' + tableident + '/';
        this.communication.sendRequest(url);
    },

    getNavigator: function() {
        var url = 'BPGetNavigator';
        this.communication.sendRequest(url);
    },

    staticMethod: function(objectname, methodname, parameters, xmlparameters) {
        var url = 'BPMethod?objectname=' + objectname + '&methodname=' + methodname + '&parameters=' + parameters + '&xmlparams=' + xmlparameters + '/';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja prosi serwer o przesłąnie formy startowej dla profilu
    */
    runStartUp: function() {
        var url = 'BPRunStartUp';
        this.communication.sendRequest(url);
        App.sessionStorage.set("StartMethodExecuted", true);
    },

    getProfilName: function(profile) {
        var url = 'BPGetProfilName?profile=' + profile + '/';
        this.communication.sendRequest(url);
    },

    getNeosVersion: function () {
        var url = 'BPGetNeosVersion';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja buduje XML z informacją o zmianie wartości pola w kontrolce.
    * @param {object} sobject - Obiekt kontrolki dla której zmieniła się wartość
    * @param {bool} manualedit - czy zmiana wartości pola nastąpiła w wyniku ręcznej edycji pola.
    * @param {bool} sendBaseValue - czy dla pól słownikowanych wysyłać także wartość pola bazowego.
    * Zykle wysyłamy pole bazowe, ale nie robimy tego w sytuacji, gdy ktoś ręcznie wpisze wartość do pola EDIT ze słownikiem
    */
    createXml: function (object, manualEdit, sendBaseValue) {
        var dataset = object.dataset;
        var fields = dataset.fields;
        var value;
        var result = {};

        var len = fields.length;
        var xml = new XmlBuilder();
        xml.openNode("DataChanges");
        if (object.dataFieldUUID == null) {
            //wysy�ane kiedy chcemy przes�a� informacje o wszystkich polach np. NotificationData
            for (var i = 0; i < len; i++) {
                xml.openNode("DataChange");
                var field = object.findTraceField(fields[i].fieldUUID);
                xml.addTextNode('fielduuid', field.dataFieldUUID);

                var dict = field.dictFieldUUID != null && !object.isCheckComboboxFromParameter ? field.dictFieldUUID : "";
                xml.addTextNode('dictfielduuid', dict);

                var modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
                if (dict) {
                    modelFieldName = dataset.getFieldModelDictKey(field.dataFieldUUID, dict);
                }

                value = dataset.model[modelFieldName];
                if (fields[i].convertToServerFormat)
                    value = fields[i].convertToServerFormat(value);

                xml.addTextNode("val", value);
                xml.closeNode();
            }
        } else {
            var field = object.findTraceField(object.dataFieldUUID);
            xml.openNode("DataChange");
            xml.addTextNode('fielduuid', field.dataFieldUUID);

            var dict = field.dictFieldUUID != null && !object.isCheckComboboxFromParameter ? field.dictFieldUUID : "";

            sendBaseValue = sendBaseValue && (field.dictFieldUUID != null);

            xml.addTextNode('dictfielduuid', dict);

            var modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
            var fieldName = field.dataFieldUUID;
            if (dict) {
                modelFieldName = dataset.getFieldModelDictKey(field.dataFieldUUID, dict);
                fieldName += "." + dict;
            }

            value = dataset.model[modelFieldName];
            if (value === null)
              value = "";

            var datasetfield = dataset.fields.getValue(fieldName);
            if (datasetfield.convertToServerFormat)
                value = datasetfield.convertToServerFormat(value);

            xml.addTextNode("val", value);
            xml.addTextNode("manualedit", manualEdit==true ? "Y" : "N");
            xml.addTextNode("datasetinstanceid", field.datasetInstanceID);
            xml.closeNode();

            if (sendBaseValue) {
              //jeśli wysyłamy pole słownikowane, to wkładamy drugi node z polem złączeniowym słownika
              xml.openNode("DataChange");
              xml.addTextNode('fielduuid', field.dataFieldUUID);
              xml.addTextNode('dictfielduuid', "");

              modelFieldName = dataset.getFieldModelKey(field.dataFieldUUID);
              fieldName = field.dataFieldUUID;

              value = dataset.model[modelFieldName];
              if (value === null)
                value = "";

              datasetfield = dataset.fields.getValue(fieldName);
              if (datasetfield.convertToServerFormat)
                value = datasetfield.convertToServerFormat(value);

              xml.addTextNode("val", value);
              xml.addTextNode("manualedit", "N");
              xml.addTextNode("datasetinstanceid", field.datasetInstanceID);
              xml.closeNode();
            }

        }
        xml.closeNode();
        result.value = xml.toString();

        return result;

    },

    closeAllForms: function(profile) {
        var url = 'BPCloseAllForms';
        this.communication.sendRequest(url);
    },

    showActionURL: function(action) {
        var url = 'BPShowActionUrl?action=' + action + '/';
        this.communication.sendRequest(url);
    },

    requestLogs: function (logs) {
        var url = 'BPRequestLogs';
        this.communication.sendRequest({
            url: url,
            method: 'post',
            value: {
                logs: logs
            }
        });

        this.communication.sendRequest(url);
    },

    getNumberOfRows: function (datasetinstanceid) {
      var url = 'BPGetNumberOfRows?datasetinstanceid=' + datasetinstanceid + '/';
      this.communication.sendRequest(url);
    }
}

function Common(communication) {
    this.communication = communication;
};

Common.prototype = {
    //Funkcje z pliku AppSrvCommonFuncs

    debugLog: function(msg, useMainLog) {
        var url = 'DebugLog?msg=' + msg + '&useMainLog=' + useMainLog + '/';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja logująca nas do aplikacji
    */
    loginUserByLogin: function(login, password, clientVersion, profile, oldPassword, device) {
        App.sessionStorage.set("StartMethodExecuted", false);
        var url = 'LoginUserByLogin?login=' + login + '&password=' + password + '&clientVersion=' + clientVersion + '&profile=' + profile + '&oldPassword=' + oldPassword + '&device=' + device + '/';
        this.communication.sendRequest(url);
    },

    /**
    * Funkcja logująca nas do aplikacji
    */
    loginUserByGoogle: function(login, token, clientVersion, profile, oldPassword, device) {
      App.sessionStorage.set("StartMethodExecuted", false);
      var url = 'LoginUserByGoogle';
      this.communication.sendRequest({
          url: url,
          method: 'post',
          value: {
              login: login,
              token: token,
              clientVersion: clientVersion,
              profile: profile,
              oldPassword:oldPassword,
              device:device
          }
      });
    },

    /**
     * Funkcja wylogująca użytkownika i zamykająca wszystkie otwarte formy
     */
    logoutUser: function() {
        var url = 'LogoutUser';
        this.communication.sendRequest(url);
    },
};

/**
 * Obiekt odpowiedzialny za komunikację z serwerem, przy pomocy tak zwanego websocketa.
 * Dzięki temu możemy odbierać komunikaty z serwera w czasie rzeczywistym.
 * @param {string} url - adres websocketa po stronie serwera
 * @param {object} communication - obiekt wspolpracujacy, głowny obiekt komunikacji
 * @constructor
 */
function NeosWebsocket(url, communication) {
    this.url = url;
    this.communication = communication;
}

NeosWebsocket.prototype = {
    /**
     * Połączenie z websocketem na zadanym adresie, przypięcie głównego zdarzenia, na otwarcie połączenia.
     * @instance
     */
    connect: function() {
        var self = this;
        this.socket = new WebSocket(this.url);
        this.socket.onopen = this.onopen.bind(this);
        this.socket.onerror = this.onerror.bind(this);
    },

    /**
     * Po uzyskaniu połączenia rejestruje w Neosie, jaki ma token sesji
     * @param {object} e - zdarzenie wygenerowane przy otwarciu websocketa
     * @instance
     */
    onopen: function(e) {
        if(this.watcher) {
          clearInterval(this.watcher);
          delete this.watcher;
        }
        this.socket.send("CONNECT " + this.communication.neosUserToken+" "+this.communication.App.UUID);
        this.socket.onmessage = this.onmessage.bind(this);
        this.socket.onclose = this.onclose.bind(this);
    },

    /**
     * Obsługuje odebranie komunikatu od websocketa. Po poprawnej rejestracji dostajemy komunikat zwrotny
     * CONNECT OK. Następnie oczekujemy na komunikaty refreshclient, po ktorych wiemy, że musimy pobrać
     * nową kolejkę komunikatów. Metoda ustawia flagę logiczną this.sustain po to, aby się zorientować, że
     * połączenie po zerwaniu należy próbować odświeżyć
     * @param {object} e - zdarzenie wygenerowane przy odebraniu komunikatu z websocketa
     * @instance
     */
    onmessage: function(e) {
        if (e.data == "CONNECT OK")
            this.sustain = true;
        else if (e.data == "REFRESHCLIENT")
            this.refreshclient();
        else if (e.data == "DISCONNECT") {
            this.sustain = false;
            this.close();
        } else if (e.data == "ERROR") {
            console.error(e);
            this.sustain = false;
        } else {
            console.info("Unknown websocket command " + e.data);
        }
    },

    /**
     * Zamyka połączenie
     * @instance
     */
    close: function() {
        if (this.socket) {
            this.socket.close();
            delete this.socket;
        }
    },

    /**
     * Obsługuje błąd websocketa.
     * @param {object} e - zdarzenie wygenerowane przy błędzie websocketa
     * @instance
     */
    onerror: function(e) {
        console.error(e);
        this.close();
    },

    /**
     * Metoda reaguje na zamknięcie socketa. Jeżeli powinniśmy próbować
     * utrzymać połączenie, ponawia próbę co pięć sekund
     * @param {object} e - zdarzenie wygenerowane przy zamknięciu websocketa
     * @instance
     */
    onclose: function(e) {
        if (this.sustain) {
            this.close();
            var self = this;
            if(!this.watcher) {
              this.watcher = setInterval(function() {
                  console.info("attempt to reconnect to websocket " + self.url)
                  self.connect();
              }, 5000);
            }
        }
    },

    /**
     * Metoda reaguje na żądanie odświeżenia komunikatów z serwera
     * @instance
     */
    refreshclient: function() {
        this.communication.refreshclient();
    }
};

/**
 * Obiekt odpowiedzialny za komunikację z serwerem, wykonywanie zdalnych funkcji i interpretację przychodzących komunikatów.
 * @param {object} application - obiekt reprezentujący całą aplikację
 * @param {string} profile - od PR69526 cała komunikacja podaje na jaki profil jest zalogowany użytkownik
 * @constructor
 */
function Communication(application, profile) {
    //QuequeMessage - globalna kolejka do przechowywania komunikat�w
    this.quequeMessage = [];
    this.App = application;
    this.BP = new BP(this);
    this.Common = new Common(this);
    this.Gui = undefined;
    this.message_profiler = false;
    //firebug specific
    this.neosUserToken = this.getNeosUserToken(profile);
    this.profile = profile;
};

Communication.prototype = {
  /**
   * Metoda inicjuje połączenie websocketowe
   * @instance
   */
    addWebsocket: function(url) {
        this.Websocket = new NeosWebsocket(url, this);
        this.Websocket.connect();
    },
    /**
     * Metoda inicjuje połączenie websocketowe
     * @instance
     */
    refreshclient: function() {
        console.info("refreshclient");
        this.BP.getMessages();
    },

    /**
     * Metoda zwraca dodatkowy nagłowek autoryzujący dla odata
     * @param {boolean} omiń X- w nazwie nagłówka
     * @return {object} obiekt zawierający nagłówek jako klucz
     */
    getOdataCustomHeaders : function(noX) {
        var profile = this.getProfile();
        var neosTokenName = this.getNeosUserTokenName(profile);
        if(typeof noX == 'undefined')
          neosTokenName = "X-"+neosTokenName;
        var neosToken = this.getNeosUserToken(profile);
        var customHeaders = {}
        customHeaders[neosTokenName] = neosToken;
        customHeaders['_ssid_'] = App.UUID;
        return customHeaders
    },

    /**
     * Metoda zwraca poprawną nazwę NeosUserTokena dla podanego profilu
     * @param  {string} profile nazwa profilu
     * @return {string} nazwa NeosUserTokena
     */
    getNeosUserTokenName: function(profile) {
        var userTokenName = 'NeosUserToken';
        if (typeof profile != 'undefined' && profile != '')
            userTokenName += '_' + profile;
        return userTokenName;
    },

    /**
     * Metoda zwraca nazwę klucza w ciasteczku gdzie dla zadanego profilu znajdziemy neosUserToken
     */
    getNeosUserToken: function(profile) {
        var userTokenName = this.getNeosUserTokenName(profile);
        return this.getCookie(userTokenName);
    },

    getCookie: function(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2)
            return parts.pop().split(";").shift();
    },

    /**
     * Metoda zwraca nazwę aktywnego profilu
     * @return {string} Nazwa aktywnego profilu
     */
    getProfile:function() {
        if (this.profile && this.profile != "") {
            return this.profile;
        } else if (App.Settings && App.Settings.Profile) {
            return App.Settings.Profile;
        }
        return "";
    },

    /**
     * Metoda potrafi wząć url taki jak zwracane są z wszystkich funkcji BP itp i jeżeli nie ma tam
     * parametru profile to dodaje taki parametr
     */
    addProfileParameter: function(url) {
        if (!this.profile && !App.settings)
            return url;

        if (url.indexOf("profile=") >= 0)
            return url;

        var len = url.length;
        var si = url.indexOf("/", len - 1) !== -1;
        var newurl = url;
        if (si)
            newurl = newurl.substring(0, len - 1);
        var queryparampresent = url.indexOf('?') >= 0;
        if (!queryparampresent)
            newurl += "?";
        else
            newurl += "&";

        newurl += 'profile=' + this.getProfile();

        if (si)
            newurl += '/';

        return newurl;
    },

    /**
     * Metoda potrafi wząć url taki jak zwracane są z wszystkich funkcji BP itp i jeżeli nie ma tam
     * parametru clientuuid to dodaje taki parametr
     */
    addUUIDParameter: function(url) {
        if (!App.UUID)
            return url;

        if (url.indexOf("clientuuid=") >= 0)
            return url;

        var len = url.length;
        var si = url.indexOf("/", len - 1) !== -1;
        var newurl = url;
        if (si)
            newurl = newurl.substring(0, len - 1);
        var queryparampresent = url.indexOf('?') >= 0;
        if (!queryparampresent)
            newurl += "?";
        else
            newurl += "&";

        newurl += '_ssid_=' + App.UUID;

        if (si)
            newurl += '/';

        return newurl;
    },

    /**
     * Metoda odpowiada za wysłanie ajaxowego zapytania z danymi pod wskazany adres (serwera Neosa) i obsługę odpowiedzi.
     * Niejawnie dokleja parametr z nazwą profilu którego dotyczy dane żądanie, chyba że jest on podany explicite.
     * @instance
     * @param {string} [param] - parametry do przesłania, jeżeli są stringiem są przesyłane jako querystring przez GET, w przeciwnym wypadku jako json metodą POST
     */
    sendRequest: function(param, messageId, specialPlusHandling) {
        var url, method, val;
        if (typeof param === 'string' || param instanceof String) {
            url = this.addProfileParameter(param);
            method = 'get';
        } else {
            url = this.addProfileParameter(param.url);
            method = 'post';
            val = param.value;
            if (typeof val !== 'string' && !(val instanceof String)) {
                val = JSON.stringify(val);
            }
        }
        url = this.addUUIDParameter(url)
        var self = this;
        var hasLastSlash = url.indexOf("/", url.length - 1) !== -1;
        //specjalnie dla https by apache by dragonfly
        if(hasLastSlash)
          url = url.substring(0, url.length-1);

        if(specialPlusHandling) {
            if(!this.pileOf) {
                this.pileOf = $('<div>').html("&#x1F4A9;").text();
            }
            url = url.replace(/[+]/g,this.pileOf);
        }

        url = encodeURIComponent(url);

        if (this.message_profiler) {
            console.info('profiling start');
            console.profile('message profiling');
            //firebug specific
        }

        var request;
        if (method == 'get') {
            request = $.ajaxq("MessegeQueue", {
                url: url,
                async: false
            });
        } else if (method == 'post') {
            request = $.ajaxq("MessegeQueue", {
                type: "POST",
                url: url,
                data: val,
                contentType: 'application/json; charset=utf-8'
            });
        }
        request.done(function(data) {
                self.addMessageToQueue(data);
                self.executeMessages(messageId);
                if (self.message_profiler) {
                    console.profileEnd();
                    //firebug specific
                    console.info('profiling stop');
                }
                App.requestLog.add(true);
            })
            .fail(function (data) {
                App.requestLog.add(false, data.statusText);
                if (data.status == 403)
                    Utils.refreshURL("login.html");
                else
                    console.error(data.statusText);
            });
    },

    //Funkcja wyci�ga z przes�anego komunikatu wiadomo�ci i umieszcza je w kolejce komunikat�w
    addMessageToQueue: function(data) {
        var startTagPosition = data.indexOf(':- ');

        if (startTagPosition >= 0) {
            var val = data.substring(startTagPosition + 3),
                xmlDoc,
                isXML = true;

            //Sprawdzamy czy warto�� wiadomo�ci jest w postaci XML
            try {
                xmlDoc = $.parseXML(val)
            } catch (e) {
                isXML = false;
            }
            var self = this;
            //dodanie wiadomo�ci do kolejki z xmla

            if (isXML) {
                var messages = $(xmlDoc).find('message');
                var len = messages.length;
                for (var i = 0; i < len; i++) {
                    var formUUID = $(messages[i]).children('UUID').text();
                    self.quequeMessage.push({
                        data: messages[i],
                        isXML: true,
                        formUUID: formUUID
                    });
                }

            }
            //dodanie wiadomo�ci do kolejki w przypdaku gdy	 widomo�� nie jest w postaci xml
            else {
                //dodanie wiadomo�� na ostatni� pozycj� listy wiadomo�ci
                this.quequeMessage.push({
                    data: val,
                    isXML: false
                });
                //console.info("Polecenie nie XML: " + data);
            }
        } else
            console.error("Nierozpoznane polecenie " + data);
    },

    /**
     * Zmienna do blokowania wykonywania komunikatów z kolejki.
     * Jeżeli jest większa od zera to komunikaty nie będą przetwarzane
     */
    blockMessageQueue: 0,

    /**
     * Funkcja blokuje wykonywanie komunikatów poprzez zwiększanie wartości zmiennej blockMessageQueue
     */
    lockMsgQueue: function() {
        this.blockMessageQueue++;
    },

    /**
     * Funkcja blokuje wykonywanie komunikatów poprzez zmniejszanie wartości zmiennej blockMessageQueue
     */
    unlockMsgQueue: function () {
        this.blockMessageQueue--;
    },

    /**
     * Główna pętla obsługi komunikatów przychodzących z serwera.
     * @param {number} [messageId] - opcjonalny parametr związany z obsługą przetwarzania ShowForm
     * @param {string} [formsToRefresh] - opcjonalny parametr związany z obsługą przetwarzania ShowForm
     */
    executeMessages: function(messageId, formsToRefresh) {
        if (this.blockMessageQueue > 0)
            return;

        var self = this;
        if (!formsToRefresh) {
            formsToRefresh = new Hashmap(function(form) {
                return form.formInstanceID;
            });
        }

        while (this.quequeMessage.length > 0) {
            var command,
                val,
                MessageInstanceID,
                id,
                uuid,
                symbol,
                xmlDoc;

            var currentMessage = this.quequeMessage.shift();
            if (currentMessage) {
                if (currentMessage.isXML) {
                    command = $(currentMessage.data).find('Command').text();
                    val = $(currentMessage.data).find('Val').text().split("&#x20;").join(" ");
                    MessageInstanceID = $(currentMessage.data).find('MessageInstanceID').text();
                    id = $(currentMessage.data).find('ID').text();
                    uuid = $(currentMessage.data).find('UUID').text();
                    symbol = $(currentMessage.data).find('Symbol').text();

                    var form;
                    if (App.gui && App.gui.navigator &&
                        command != "SSHOWFORM" && // Showform inaczej jest obs�ugiwany i on sam doda form� do kolekcji
                        command != "SSHOWMESSAGE" && // SSHOWMESSAGE wy�wietla okno komunikatu, kt�re jest wy�wietlane jednorazowo
                        id != "0") {
                        form = App.gui.navigator.formStack.findForm(id).form;

                        if (!form) {
                            form = App.gui.getFormContainingDataset(id, "executeMessages");
                            if (!form)
                                console.info("nie uda�o si� znale�� formy z datasetem o id " + id);
                        }

                        if (form) {
                            if (command == "SCLOSEFORM") {
                                //skoro formę zamykamy to na pewno nie będzie sensu jej layoutować
                                formsToRefresh.remove(form);
                            } else if (form.formStyle != "M" || (form.formStyle == "M" && form.stackCurrentForm)) {
                                //jeśli forma jest modalna lub MDI ale tylko ta na wierzchu, to będzie potrzeba aby ją layoutować
                                //po zakończeniu przetwarzania komunikatów
                                //BS 138542 - ustawiam tutaj lockLayout bo wiem, że zrobię refresh tej formy.
                                //gdy flaga była ustawiana dla każdej formy to przestawało działać zwijanie paneli
                                form.lockLayout = true;
                                formsToRefresh.add(form);
                            }
                        }
                    }

                    //console.info("MessageInstanceID: " + MessageInstanceID + ". command: " + command + ". val: " + val + ". id: " + id + ". uuid: " + uuid + ". symbol: " + symbol)
                    //wywolanie komendy przeslanej w komunikacie
                    switch (command) {
                        case 'SDEBUGLOG':
                            this.showDebug(val);
                            break;
                        case 'SGETSETTINGS':
                            this.App.getSettings(val);
                            this.App.addgui();
                            this.App.gui.init(window.run);
                            break;
                        case 'SSHOWFORM':
                            this.lockMsgQueue();
                            var newFormWillBeShown = App.gui.showForm(id, uuid, val, currentMessage.formUUID, messageId, formsToRefresh);
                            //jeśli nowa forma będzie pokazana, to ona wywołaa przetworzenie pozostałych komunikatów w kolejce
                            if (newFormWillBeShown)
                              return;
                            break;
                        case 'SCLOSEFORM':
                            App.gui.navigator.closeForm(id);
                            break;
                        case 'SUPDATEFORM':
                            this.Gui.updateFormByID(id, uuid, symbol, val);
                            break;
                        case 'SUPDATEDATA':
                            this.Gui.updateData(id, uuid, symbol, val);
                            break;
                        case 'SVALIDATEDATA':
                            this.Gui.validateData(id, uuid, val);
                            break;
                        case 'SLOCATERECORD':
                            this.Gui.locateRecord(id, uuid, val, symbol);
                            break;
                        case 'SUPDATEDATASET':
                            this.Gui.updateDataset(id, symbol, val);
                            break;
                        case 'SUPDATEDATASETSTATE':
                            this.Gui.updateDatasetState(id, symbol, val);
                            break;
                        case 'SDATASETACTION':
                            this.Gui.datasetAction(id, symbol, val);
                            break;
                        case 'SNOTIFYDATA':
                            this.Gui.notifyData(id);
                            break;
                        case 'SCALLFUNCTION':
                            this.Gui.callFunction(id, uuid, symbol, val);
                            break;
                        case 'SCALLACTION':
                            this.Gui.callAction(id, uuid, symbol, val);
                            break;
                        case 'SGETRESOURCES':
                            this.Gui.getResources(val);
                            break;
                        case 'SHOWPOPUPMENU':
                            this.Gui.showPopupMenu(id, uuid, val);
                            break;
                        case 'SSHOWMESSAGE':
                            if (this.Gui)
                                this.Gui.showMessage(val);
                            break;
                        case 'SCHANGENOTIFICATION':
                          if (this.Gui && this.Gui.navigator && this.Gui.navigator.kendoToolbar)
                            this.Gui.navigator.addNotificationElement(val, true);
                            break;
                        case 'SGETPROFILNAME':
                            $("#labelProfileName").text(val);
                            $("#labelProfileName").removeClass("Hide");
                            $("#mainMenuTitle").text(val);
                            break;
                        case 'SFILETRANSFER':
                            this.sFileTransfer(val);
                            break;
                        case 'SGETNEOSVERSION':
                            App.Version = val;
                            console.info("!!! Wersja Neosa: '" + val + "' !!!");
                            break;
                        case 'SREQUESTLOGSAVED':
                            if (val == 'Y') {
                                window.clearInterval(App.requestLog.timerSendToServer)
                                App.requestLog.markLogsAsSent();
                            }
                            break;
                        case 'COPYTOCLIPBOARD':
                            Utils.copyToClipboard(val);
                            break;
                        default:
                            console.info("Nierozpoznane polecenie XML:" + command);
                            break;
                    }
                } else {
                    this.customCommand(currentMessage);
                }
            }
        }
        //wykonaj layout form w kolekcji, ale tylko tych, które tego potrzebują
        this.refreshForms(formsToRefresh);
    },

    /*
     * Metoda wyświetla w konsoli javascriptu komunikat przysłany z serwera za pomocą funkcji DEBUG.Log
     */
    showDebug: function(val) {
        var doc = $.parseHTML("<debug>" + val + "</debug>");
        var type = $("img", doc).attr("src");
        $("img", doc).attr("src", "");
        var text = $(doc).text();
        switch (type) {
            case "MI_STOP":
                console.error(text);
                break;
            case "MI_EXCLAMATION":
                console.warn(text);
                break;
            default:
                console.info(val);
                break;
        }
    },

    sFileTransfer: function(xml) {
        if (xml) {
            var $xml = $(xml);

            var id = $xml.attr('transferid');
            var direction = $xml.attr('direction');
            var repository = $xml.attr('repository');
            var filename = $xml.attr('filename');
            var localfilepath = $xml.attr('localfilepath');
            var filesize = $xml.attr('filesize');

            if (direction == "D") {
                //download
                this.sDownloadFile(id);
            }
        }
    },

    sDownloadFile: function(id) {
        var url = App.getRelativeCurrentURL();
        var self = this;
        url += 'download?transferid=' + id;
        url = this.addProfileParameter(url);

		if(this.Gui.device.ios) {
			var windowReference = window.open(url);
			windowReference.onload = function() {
				onSuccess();
			}
			if(!windowReference || windowReference.closed || typeof windowReference.closed=='undefined')
			{
				 onError("Wyskakujące okna są zablokowane. Odblokuj je w ustawieniach");
			}
		}
		else
		{
			var timer = window.setInterval(checkSuccess, 1000);

			var $idown = $('<iframe>', {
					id: 'idown',
					src: url
				})
				.hide()
				.appendTo('body');
			$idown.load(function() {
				//gdy sukces, nie odpali się w chromie
				window.clearInterval(timer);
				var content = $idown.contents().text();
				if (content.length > 0) {
					onError(content);
				} else {
					onSuccess();
				}
			});
		}

        function checkSuccess() {
            var cookie = self.getCookie('file_' + id);
            if (cookie && cookie == 'ok') {
                window.clearInterval(timer);
                onSuccess();
            }
        }

        function onSuccess() {
            sendMessage(id, 'ok');
        }

        function onError(error) {
            var xml = $('<div/>');
            xml.attr('T', error);
            xml.attr('C', "Błąd transferu plików");
            xml.attr('Ic', '3');
            xml.attr('B', 'True');
            self.Gui.showMessage(xml);
            sendMessage(id, 'error', error);
        }

        function sendMessage(id, status, message) {
            var xml = new XmlBuilder();
            xml.openNode('FileInfo');
            xml.addTextNode('status', status);
            if (message)
                xml.addTextNode('message', message);
            xml.closeNode();
            var xmlstr = xml.toString();

            self.BP.fileTransferEnd(id, xmlstr);
        }
    },

    /**
     * Metoda dla form w przekazanej kolekcji wyłącza znacznik blokowania layoutu i wykonuje layout (rozmieszczenie komponentów na ekranie)
     * Efektywnie jest to wykonywane tylko dla tych form, które otrzymały znacznik, że tego potrzebują (needLayout)
     */
    refreshForms: function (fs) {
        var forms = fs.getAll();
        var len = forms.length;

        for (var i = 0; i < len; i++) {
            var form = forms[i];
            form.lockLayout = false;
            form.refreshLayout();
            if (!form.form.hideForFirstTime) {
                form.form.hideForFirstTime = true;
            }
        }
    },

    /**
     * Funkcja do obsługi komunikatów, które nie są wymienione w switch głównej pętli przetwarzania komunikatów
     * @param {string} currentMessage - wiadomość przesłana komunikatem od serwera
     */
    customCommand: function(currentMessage) {
        //pobranie parametr�w z komunikatu
        var tmp = currentMessage.data.split(String.fromCharCode(13, 10));

        //Poniższy kod opiera się na założeniu, że jedynym custom command zaczynającym się od "<Div"
        //jest komunikat zwracany przez BPGetNavigator. To może ulec zmianie, dlatego zostawiam ten komentarz.
        if (tmp.length > 0 && tmp[0].indexOf('<Div') == 0) {
            this.Gui.initNavigator($(tmp[0]));
            App.runAction();
            App.gui.resizeWrapper();
            App.gui.navigator.addBackButton();
            App.gui.navigator.hideBackButton();
            App.gui.navigator.addLabel("profileName", App.Settings.ProfileName || "");
            if (App.gui.navigator.setCss) {
                App.gui.navigator.setCss();
            }
            $("#mainMenuTitle").text(App.Settings.ProfileName || "");
            Utils.hideProgress();

            //Robimy małe opóźnienie bo zdarzało się że sporadycznie wyświetlało się menu
            //bez żadnej pozycji (Było samo szare tło). Po dodaniu opóźnienia nie udało się
            //odtworzyć tej sytuacji.
            setTimeout(function() {
                if (App.localStorage.get("pinMenu") == "1") {
                    App.gui.navigator.pinMenuFromStart();
                }
            }, 50);

            if (App.action == ""){
              if(!App.sessionStorage.getBool("StartMethodExecuted")) {
                //opóźniamy pokazanie pierwszego okna aż się załadują wszystkie style. Inaczej mogą źle działać pomiary.
                setTimeout(function () { App.communication.BP.runStartUp(); }, 50);
              }
              App.communication.BP.getNotificationsNow();
            }

            return;
        }
        var params = {};

        $(tmp).each(function() {
            if (this != "") {
                var record = this.split(":- ");
                params[record[0]] = record[1];
            }
        });

        //obs�uga funkcji loguj�cej
        if (params.hasOwnProperty('UserLogged')) {
            if (params.UserLogged == "True") {
                var url = location.origin;
                if (!url.endsWith("index.html"))
                  url += "/index.html";
                Utils.refreshURL(url);
            } else
              if (params.UserLogged == "False") {
                App.gui.signOut(function () {
                  var url = location.origin;
                  if (!url.endsWith("login.html"))
                    url += "/login.html";
                  Utils.refreshURL(url);
                  alert(params.Message);
                });
            } else {
                alert(params.Message);
            }
        } else if (params.hasOwnProperty('UserLoggedOut')) {
            //je�li dostajemy komunikat zwrotny dla fukcji logoutUser to nic nie robimy
        } else {
            //sprawdzenie czy klient zalogowany
            alert("Komunikat bez komendy: " + currentMessage.data);
        }
    }
}

/// <reference path="../jquery/jquery-1.10.2-vsdoc.js"/>
/// <reference path="../kendo/vsdoc/kendo.all-vsdoc.js"/>
'use strict';

function Gui(application) {
    if (application)
        this.communication = application.communication;

    this.divWindows = $("#windows");

    this.device = Device();
    this.device.handleOrientation = function () {
        this.resizing();
    }
    this.device.init();

    this.margin = 2;
    this.border = 1;
    this.idgenerator = 1;
    this.paddingForArtificialPanel = 2;
    this.mobileVersion = this.device.tablet || this.device.mobile;
    this.maxPanelHeaderHeight = 0; //zmierzona maksymalna wysokosc nagłówka panela
    this.maxTabStripHeaderHeight = 0; //zmierzona maksymalna wysokość nagłówka zwykłego tabstripa
    this.maxBigTabStripHeaderHeight = 0; //zmierzona maksymalna wysokość nagłówka dużego tabstripa
    this.maxControlHeaderHeight = 0;

    this.maxTimeStampWrapperBorderHeight = 0;
    this.maxTimeStampWrapperBorderWidth = 0;
    this.maxTimeStampWrapperPadding = 0;

    this.maxDateWrapperBorderHeight = 0;
    this.maxDateWrapperBorderWidth = 0;
    this.maxDateWrapperPadding = 0;

    this.maxDropdownWrapperBorderHeight = 0;
    this.maxDropdownWrapperBorderWidth = 0;
    this.maxDropdownWrapperPadding = 0;

    this.mouseLastPosX = 0;
    this.mouseLastPosY = 0;
  }

Gui.prototype = {

    layout: function (btns, okno) {

        var n = btns.length;
        var self = this;
        var icon_name, image, button_label, button_id, button, panel, width, text_but;

        panel = $('<div class="panel">');
        for (var i = 0; i < n; i++) {

            //ID przycisku
            button_id = btns[i].id;
            text_but = "<button class='button k-button' id='" + button_id + "'>";


            // Label przycisku
            button_label = btns[i].label;
            text_but = text_but + " " + button_label + "</button>";
            button = $(text_but);

            button.click(button_id, function (arg) {
                //Najpierw zamykam okno dialogowe a następnie wykonuje akcję.
                //Gdy jest odwrotnie to przy obsłudze komunikatów ta forma zostaje znaleziona (forma Tak, Nie)
                //jako forma na samej górze i to do niej jest uruchamiana logika. Co jest bez sensu
                //ponieważ zaraz okno zostało zamykane!!!.
                okno.close();
                //DN: dodałem destory aby usuwać okno z drzewa DOM.
                okno.destroy();
                self.communication.BP.callAction(arg.data);
            });

            //Ikona
            if (btns[i].icon) {
                icon_name = btns[i].icon;
                image = Utils.getSpriteIconForButton(icon_name, true);
                button.prepend(image);
            }
            //Dodaj przycisk do panelu
            panel.append(button);
        }

        return panel;
    },

    //pobranie sentecent
    getUnit: function () {
        if (!this.unit) {
            this.unit = 26;
        }
        return this.unit;
    },

    /**
     * Fukcja przelicza wysokość podaną w SM na pixele
     * @param {int} val - wartość w SM
     * @param {boolean} noMin - flaga, która mówi czy mamy zwracać minVal w przypadku
                                gdy wyliczona wartość jest mniejsza od minimalnej
     */
    scaleHeight: function (val, noMin) {
        if (typeof val !== 'undefined') {
          var minValue = this.getUnit();
          var value = this.getUnit() * val;
          if (value < minValue && !noMin) {
            return minValue;
          } else {
            return value;
          }
        }
    },

    /**
     * Fukcja przelicza szerokość podaną w SM na pixele
     * @param {int} val - wartość w SM
     * @param {boolean} noMin - flaga, która mówi czy mamy zwracać minVal w przypadku
                                gdy wyliczona wartość jest mniejsza od minimalnej
     */
    scaleWidth: function (val, noMin) {
        if (typeof val !== 'undefined') {
          var minValue = this.getUnit();
          var value = this.getUnit() * val;
          if (value < minValue && !noMin) {
            return minValue;
          } else {
            return value;
          }
        }
    },

    nextId: function () {
        return (this.idgenerator++);
    },

    _today : function() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      if (dd < 10) {
          dd = '0' + dd
      }

      if (mm < 10) {
          mm = '0' + mm
      }

      var yyyy = today.getFullYear();
      var yy = (yyyy + '').substring(2, 4);
      today = yy + mm + dd;
      return today;
    },

    //funkcja do logowania uzytkownika
    login: function (user, password) {
        App.localStorage.set("login", user);
        var login = user;
        var oldPassword = CryptoJS.MD5(password).toString().toUpperCase();
        var password = CryptoJS.MD5("SENTE_" + password + "_NewPass").toString().toUpperCase();

        App.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
        var device = this.getCurrentDevice();

        this.communication.Common.loginUserByLogin(login, password, this._today(), App.profile, oldPassword, device)
    },

    loginByGoogle: function(googleUser) {
      var id_token = googleUser.getAuthResponse().id_token;
      var profile = googleUser.getBasicProfile();
      var email = profile.getEmail();

      App.profile = Utils.operationsUrlParameters.getURLParameterValue("profile") || "";
      var device = this.getCurrentDevice();

      this.communication.Common.loginUserByGoogle(email, id_token, this._today(), App.profile, "", device)
    },

    signOut:function(callback) {
      if(typeof(gapi) == "undefined"){
        callback();
        return;
      }
      var auth2 = gapi.auth2.getAuthInstance();
      if(auth2)
        auth2.signOut().then(callback);
      else callback();
    },

    getCurrentDevice: function () {
        if (this.device.mobile)
            return 'phone';
        else if (this.device.tablet)
            return 'tablet';
        else return 'www';
    },

    getFirstSObject: function (element) {
        var $element = jQuery(element);
        var sobj = $element.data('SObject');
        while ($element && $element.length > 0 && !sobj) {
            $element = $element.parent();
            var sobj = $element.data('SObject');
        }
        return sobj;
    },


    /**
     * Funkcja pokazuje prosty balloon hint z tekstem (komunikat na dole ekranu)
     */
    showBalloonHint: function (text) {
      var xml = $('<div/>');
      xml.attr('T', text);
      xml.attr('C', "");
      xml.attr('Ic', "");
      xml.attr('B', 'True');
      this.showMessage(xml);
    },

    /**
     * Funkcja służy do wyświetlania wiadomości w postaci okienka albo baloonHint
     * @param {object} xml - xml z wiadomością
     */
    showMessage: function (xml) {
        var caption = $(xml).attr("C") || "";
        var text = $(xml).attr("T") || "";
        var icon = $(xml).attr("Ic");
        var balloon = $(xml).attr("B");
        var formInstance = $(xml).attr("Fi");
        var action = $("Action", xml);

        switch (icon) {
            case "0": //WARNING
                icon = "MI_EXCLAMATION";
                break;
            case "1": //INFORMATION
                icon = "MI_INFORMATION";
                break;
            case "2": //QUESTION
                icon = "MI_QUESTION";
                break;
            case "3": //STOP
                icon = "MI_STOP";
                break;
        }

        var img = Utils.getSpriteIcon(icon, true);

        if (balloon == "True") {
            var self = this;
            var wrapper = $("<div class='notificationBox'/>");

            img = Utils.getSpriteClasses(icon, true);
            var buttonImg = '\\Icon\\x-mark.png';
            if (!this.notifications) {
                var position = {
                  bottom: 30,
                  right: 5,
                  pinned: true,
                };
                var stacking = "up";

                this.notifications = wrapper.kendoNotification({
                    stacking: stacking,
                    button: true,
                    position: position,
                    autoHideAfter: 16000,
                    hideOnClick: true,
                    show: function (e) {
                        $(".notificationBox", e.element).click(function (e) {
                            if ($(e.target).attr("id") != "notificationBoxCloseButton") {
                                var actionUUID = "";
                                if (App.gui.navigator.hasAction(actionUUID)) {
                                    var action = App.gui.navigator.getAction(actionUUID);
                                    App.communication.BP.staticMethod(action.objectUUID, action.methodName, "");
                                }
                                else {
                                    self.communication.BP.callAction(actionUUID);
                                }
                            }
                        });
                    },
                    templates: [{
                        type: "info",
                        template: "<div class='notificationBox' actionUUID='#: actionUUID#'><div class='notificationBoxButtons'><img id='notificationBoxCloseButton' src='#: buttonImg#'></div><div class = 'notificationBoxBody'><div class='notificationBoxBodyIcon'><span class='#: image#'><div class = 'notificationBoxIcon'></div></div><div class='notificationBoxBodyMessage'><span>#= title #</span><p>#= message#</p></div></div></div>"
                    }]
                }).data("kendoNotification");
            }

            if (caption.length > 0) {
                caption = caption.replace(/\n/g, '<br/>');
            }
            if (text.length > 0) {
                text = text.replace(/\n/g, '<br/>');
            }

            this.notifications.show({
                image: img,
                title: caption,
                message: text,
                buttonImg: buttonImg,
                actionUUID: ""
            }, "info");
        }
        if (balloon == "False") {
            var msgForm = $("<div class='message'>");

            var div_text = "<div class = 'text'>" + text + "</div>";
            var div_ikona = "<div class = 'ikona'></div>";
            var text_gotowy = $(div_text);
            var ikona_gotowa = $(div_ikona);


            ikona_gotowa.append(img);
            msgForm.append(ikona_gotowa);
            msgForm.append(text_gotowy);
            var buttons = [], id_b = "", label_b = "", icon_b = "";

            if (action.length > 0) {
              for (var i = 0; i < action.length; i++) {
                id_b = $(action[i]).attr("ID");
                label_b = $(action[i]).attr("L");
                icon_b = $(action[i]).attr("aIc");
                buttons.push({ icon: icon_b, label: label_b, id: id_b });
              }
            }
            else {
              buttons.push({icon: "", label: "ok", id: "messageBoxOkButton"});
            }

            $("#msgs").append(msgForm);
            msgForm.kendoWindow({
                width: "380px",
                height: "180px",
                title: caption,
                actions: [
                    "Close"
                ],
                resizable: false,
                close: function(e) {
                    this.destroy();
                }
            });
            var okno = $(msgForm).kendoWindow().data("kendoWindow");
            okno.center();
            var lay = this.layout(buttons, okno);
            msgForm.append(lay);

            var windowHeight = $(".message.k-window-content.k-content").height() || 0;
            var textHeight = $(".message.k-window-content.k-content > .text").height() || 0;
            var panelHeight = $(".message.k-window-content.k-content > .panel").outerHeight(true) || 0;

            var top = windowHeight - textHeight - panelHeight;
            if (top > 0) {
                $(".message.k-window-content.k-content > .panel").css({ "top": top + "px", "position": "relative" });
            }
            else {
                $(".message.k-window-content.k-content > .panel").css({ "top": "0px", "position": "initial" });
            }
        }
    },

    /**
     * funkcja obsługująca zmianę skórki
     * @param {string} skinName - nazwa skórki
     * @param {boolean} animate - flaga mówiąca czy zmiana skórki ma być wywołana z opóźnieniem (animacja?)
     */
/*    changeTheme: function (skinName, animate) {
        var doc = document,

        kendoLinks = $("link[href*='kendo.']", doc.getElementsByTagName("head")[0]),
        commonLink = kendoLinks.filter("[href*='kendo.common']"),
        skinLink = kendoLinks.filter(":not([href*='kendo.common'])"),
        href = location.href,
        skinRegex = /kendo\.\w+(\.min)?\.css/i,
        extension = skinLink.attr("rel") === "stylesheet" ? ".css" : ".less",
        url = commonLink.attr("href").replace(skinRegex, "kendo." + skinName + "$1" + extension);

        function preloadStylesheet(file, callback) {
            var element = $("<link rel='stylesheet' media='print' href='" + file + "'/>").appendTo("head");

            setTimeout(function () {
                callback();
                element.remove();
            }, 100);
        }

        function replaceTheme() {
            var oldSkinName = $(doc).data("kendoSkin"),
                newLink;

            newLink = skinLink.eq(0).clone().attr("href", url);

            newLink.insertBefore(skinLink[0]);
            skinLink.remove();

            $(doc.documentElement).removeClass("k-" + oldSkinName).addClass("k-" + skinName);
        }

        if (animate) {
            preloadStylesheet(url, replaceTheme);
        } else {
            replaceTheme();
        }
    },*/

    /**
     * Funkcja do modyfikowania istniejącej formy.
     * @param {string} id - id formy
     * @param {string} uuid - uuid kontrolki, która ma być modyfikowana
     * @param {string} symbol - symbol właściwości, która ma być zmodyfikowana (enabled, visible, hint, value, itd.)
     * @param {string} val - wartość jaka ma zostać ustawiona dla właściwości
     */
    updateFormByID: function (id, uuid, symbol, val) {
        var form = App.gui.navigator.formStack.findForm(id);
        if (form.form) {
            form = form.form;
            var tmp = form.findAllControlWithElementUUID(uuid);

            var tmplen = tmp.length;
            for (var i = 0; i < tmplen; i++) {
                var object = tmp[i];
                this.updateForm(form, object, uuid, symbol, val);
            }

            if (tmplen == 0) {
                console.info("updateFormByID: Nie znaleziono elemntu o uuid: '" + uuid + "' na formie o id: '" + id + "' podczas próby ustawienia '" + symbol + "' na '" + val + "'");
            }
        }
        else {
            console.info("updateForm: Brak formy o id = " + id);
        }

    },

    /**
     * Funkcja służy do otwierania form z stosu
     * @param {string} id - id formy
     * @param {string} uuid - obecnie nie wykorzystywany
     * @param {string} formXml - xml z opisem formy którą mamy pokazać
     * @param {string} formUUID - uuid formy
     * @param {string} messageId - id komunikatu, który wywołał komendę showForm
     * @param {string} formsToRefresh - kolekcja form do odświeżenia po zakonczeniu kolejki komunikatów
     */
    showForm: function (id, uuid, formXml, formUUID, messageId, formsToRefresh) {
        var form = this.navigator.formStack.findForm(id);
        var self = this;
        return Utils.showProgress(function () {
            App.gui.navigator.showForm(id, uuid, formXml, formUUID, messageId);
            var form = App.gui.navigator.formStack.findForm(id);
            form.form.needLayout = true;
            if (!form.form.hideForFirstTime) {
                $(form.form.objectDOM).addClass("hideForLayout");
            }
            formsToRefresh.add(form.form);
            App.communication.unlockMsgQueue();
            App.communication.executeMessages(messageId, formsToRefresh);
            self.navigator.formStack.setSearchVisibility();
          //rekurencyjne wywołanie przetwarzania dalszej części kolejki
        }, !form.form);
    },

    updateForm: function (form, object, uuid, symbol, val, sym) {
        if (!object) {
            if (!this.updateValueActionDataset(form, uuid, symbol, val)) {
                console.info("nie znaleziono elementu o uuid #" + uuid + " w funkcji 'updateForm'");
            }
        }
        else {
            object.update(symbol, val, sym);
        }
    },

    searchActionFromDataset: function (form, uuid) {
        var datasets = form.datasets.getAll();
        var len = datasets.length;

        for (var i = 0; i < len; i++) {
            var dataset = datasets[i];
            if (dataset.actionsByUUID.containsKey(uuid)) {
                return dataset.actionsByUUID.getValue(uuid);
            }
        }
        return null;
    },

    updateValueActionDataset: function (form, uuid, symbol, val) {
        var action = this.searchActionFromDataset(form, uuid);
        if (action != null) {
            switch (symbol) {
                case "ENABLED":
                    action.enabled = val;
                    break;
                case "VISIBLE":
                    action.visible = val;
                    break;
                default:
                    console.info("Brak obsługi pola '" + symbol + "' przy zmianie wartości akcji w dataset");
            }
            return true;
        }
        else {
            return false;
        }
    },

    getFormContainingDataset: function (id, callingFuncName) {
        //szukamy w formach na stosie
        var form = $.grep(App.gui.navigator.formStack.stackForms, function (e) { return e.datasets.containsKey(id) });

        //szukamy w słownikach dla form na stosie
        if (form.length < 1) {
            form = this.getDictFormContainingDataset(App.gui.navigator.formStack.stackForms, id);
        }

        //szukamy w formach okienek
        if (form.length < 1) {
            var form = $.grep(App.gui.navigator.formStack.windowForms, function (e) { return e.datasets.containsKey(id) });
        }

        //szukamy w słownikach dla form okienek
        if (form.length < 1) {
            form = this.getDictFormContainingDataset(App.gui.navigator.formStack.windowForms, id);
        }

        if (form.length == 1) {
            return form[0];
        } else if (form.length == 0) {
            console.info(callingFuncName + ": Brak formy z datasetem o id = " + id);
        }
        else {
            console.info(callingFuncName + ": Wiecej niz jedna forma z datasetem o id = " + id);
        }
        return null;
    },

    getDictFormContainingDataset: function (collection, id) {
        var form = [];
        var len = collection.length || 0;

        for (var i = 0; i < len ; i++) {
            var item = collection[i];
            form = $.grep(item.dictForms, function (e) {
                return e.datasets.containsKey(id)
            });

            if (form.length > 0) {
                break;
            }
        }

        return form;
    },

    /**
     * funkcja aktualizuje dataset danej formy
     * @param {string} id - id datasetu
     * @param {string} uuid - uuid pola które modyfikujemy
     * @param {string} symbol - uuid pola słownikowanego
     * @param {string} val - wartość którą chcemy ustawić
     */
    updateData: function (id, uuid, symbol, val) {
        var form = this.getFormContainingDataset(id, 'updateData');
        if (form) {
            var ds = form.datasets.getValue(id);

            //update controlek
            var objects = form.findControlByDataFieldUUID(uuid, id);
            if (objects.length < 1) {
              //sprawdzamy czy w modelu danych jest pole do zaktualizowania
              if (uuid == "$SEARCHFIELD") {
                form.initFilter = val;
              }
              else if (uuid == "$SEARCHFIELDNAME") {
                 //$SEARCHFIELDNAME pod WWW jest nieobsługiwany
              }
              else if (!updateModel(ds, uuid, symbol, val)) {
                console.info("nie znaleziono elementow o uuid #" + uuid + " na formie oraz w modelu danych w funkcji 'updateData'");
              }
            }
            else {
                var self = this;
                objects.forEach(function (object) {
                    //object.lockFieldUUID jest flagą która nie ma wartości true false.
                    //W tej fladze znajduje się liczba wywołań funkcji updateData dla
                    //danego obiektu. Możemy dostać dla jedengo obiektu więcej niż 1
                    //updateData (np. zmiana wartości słownikowanej i wartość klucza relacji
                    //w odzielnych komunikatach) wykonywane asynchronicznie przez co flaga
                    //typu bool się nie sprawdzała.
                    if (!object.lockFieldUUID) {
                        object.lockFieldUUID = 0;
                    }

                    object.lockFieldUUID++;
                    self.updateForm(form, object, object.elementUUID, "VALUE", val, symbol);
                    object.lockFieldUUID--;
                });
            }

            //update pol grida
            if (ds.state == "I") {
                //do zrobienia obsługa wielu gridów
                if (ds.dependentPresenters) {
                    var len = ds.dependentPresenters.length;

                    for (var i = 0; i < len; i++) {
                        var grid = ds.dependentPresenters[i];
                        if (grid._effectiveVisible && grid.modelForControl[grid.dataset.getFieldModelKey(uuid)]) {
                            grid.pumpFieldModelToRow(uuid);
                        }
                    }
                }
                else {
                    console.info("Nie znaleziono dependentPresenters dla dataseta");
                }
            }
        }
        else {
            console.info("Nie znaleziono formy zawierającej dataset o podanym ID w funkcji Gui.updateData");
        }

        function updateModel(ds, uuid, duuid, val) {
            var result = false;
            if (ds) {
                //ustalamy klucz
                var key;
                if (duuid && duuid != "") {
                    key = ds.getFieldModelDictKey(uuid, duuid);
                }
                else {
                    key = ds.getFieldModelKey(uuid);
                }

                //sprawdzamy czy istnieje klucz w modelu
                if (ds.model[key] !== undefined) {
                    //aktualizujemy wartość modelu
                    result = true;
                    ds.model.set(key, val);
                }
                else {
                    console.info("Nie znaleziono w modelu danych szukanego pola");
                }
            }
            else {
                console.info("Nie znaleziono dataseta o podanym ID");
            }

            return result;
        }
    },

    //po nazwie zakładam że sprawdza poprawność danych po stronie klienta pisze:
    //"funkcja aktualizuje wartosc pola, gdyz otrzymala je z serwera. W fielduuid moze byc tez uuid elementu formy"
    validateData: function (id, uuid, val) {
        var form = this.getFormContainingDataset(id, 'validateLayoutData');
        if (form) {
            var objects = form.findControlByDataFieldUUID(uuid, id);
            if (!objects) {
                console.info("nie znaleziono elementow o uuid #" + uuid + " w funkcji 'validateLayoutData'");
            }
            else {
                console.info("Funkcja findControlByDataFieldUUID znalezla " + objects.length + " element(ow) o uuid #" + uuid);
                $(objects).each(function () {
                    this.updateValidate(val);
                });
            }
        }
    },

    locateRecord: function (id, uuid, val, symbol) {
        var form = this.getFormContainingDataset(id, 'locateLayoutRecord');
        if (form) {
            var position = parseInt(symbol);
            var ds = form.datasets.getValue(id);
            ds.locateRecord(position);
        }
        else {
            console.error("Nie udało się znaleźć formy zawierającej dataset o id: " + id);
        }
    },

    updateDataset: function (id, symbol, val) {
        var form = this.getFormContainingDataset(id, 'updateDataset');
        if (form) {
            var ds = form.datasets.getValue(id);
            if (ds) {
                if (ds.ourFilter) {
                    ds.oldFilter = ds.ourFilter;
                }

                ds.ourFilter = $(val).attr("F");
                if(ds.oldFilter != ds.ourFilter)
                    ds.filterWasChanged = true;
                ds.setState($(val).attr("S"), true);
                ds.update();
            }
        }
    },

    notifyData: function (id) {
        var form = this.getFormContainingDataset(id, 'notifyData');
        if (form) {
            var ds = form.datasets.getValue(id);
            if (ds) {
                ds.notifyData();
            }
        }
    },

    updateDatasetState: function (id, symbol, val) {
        console.error("Brak jeszcze implemenatcji funkcji: UpdateDatasetState");
    },

    /**
    * Metoda odpowiada za przekazanie sterowania do konkretnych metod reagujących na zdarzenia,
    * które reagują na zmiany zachodzące w datasecie. Zmiany stanu (I,B,E,C), przesuwanie rekordów itp.
    * Zmiany te są również wysyłane do wszystkich zależnych od tego dataseta prezenerów (gridy, drzewa, itp.)
    * @memberof Gui
    * @instance
    * @param {number} id - id dataseta do którego adresowana jest akcja
    * @param {string} symbol - kod akcji którą należy wykonać, zgodnie ze switchem
    * @param {string} [val] - opcjonalnie dodatkowe argumenty do danej akcji
    */
    datasetAction: function (id, symbol, val) {
        var form = this.getFormContainingDataset(id, 'datasetAction');
        var ds = form.datasets.getValue(id);

        switch (symbol) {
            case "I":
                ds.setState(symbol, true);
                break;
            case "B":
            case "E":
                ds.setState(symbol, true);
                break;
            case "C":
                ds.setState("B", true);
                ds.refreshModel();
                break;
            case "P":
                ds.dontNotifyServerAboutChange = true;
                ds.setState("B", true);
                ds.refresh();//tu było niby żeby się na tym rekordzie ustawił ale to i tak dobrze nie działało
                ds.dontNotifyServerAboutChange = false;
                break;
            case "NEXT":
                ds.next();
                break;
            case "PREV":
                ds.prev();
                break;
            case "FIRST":
                ds.first();
                break;
            case "LAST":
                ds.last();
                break;
            default:
                console.info("Przekazano nieznany symbol '" + symbol + "' akcji dataseta");
                break;
        }

        if (ds.dependentPresenters) {
            var len = ds.dependentPresenters.length;
            for (var j = 0; j < len; j++) {
                var presenter = ds.dependentPresenters[j];
                if (presenter._effectiveVisible) {
                    switch (symbol) {
                        case "I":
                            presenter.datasetActionAdd();
                            break;
                        case "D":
                            presenter.datasetActionDelete();
                            break;
                        case "E":
                            presenter.datasetActionEdit();
                            break;
                        case "P":
                            presenter.datasetActionPost();//tu było niby żeby się na tym rekordzie ustawił ale to i tak dobrze nie działało
                            break;
                        case "C":
                            presenter.datasetActionCancel();
                            break;
                        case 'SR':
                            DatasetActionSelect(ds, presenter);
                            break;
                        case 'DR':
                            DatasetActionDeselect(ds, presenter);
                            break;
                        case 'SA':
                            DatasetActionSelectAll(ds, presenter);
                            break;
                        case 'CS':
                            DatasetActionClearSelection(ds, presenter);
                            break;
                        default:
                            console.info("Przekazano nieznany symbol '" + symbol + "' akcji dataseta");
                    }
                }
            }
        }

        function DatasetActionSelect(ds, presenter) {
            presenter.multiselectRow();
        }

        function DatasetActionDeselect(ds, presenter) {
            presenter.multideselectRow();
        }

        function DatasetActionSelectAll(ds, presenter) {
            presenter.selectAll();
        }

        function DatasetActionClearSelection(ds, presenter) {
            presenter.clearAllSelection();
        }
    },

    notifyLayoutData: function (id) {
        console.info("Brak jeszcze implemenatcji funkcji: NotifyLayoutData");
    },

    callFunction: function (id, uuid, symbol, val) {
        //sprawdzamy czy mamy otworzyc plik
        if (symbol == "ShellExecute") {
            //Sprawdzamy czy nasza przglądarka to IE
            var file = val.replace('FILE=', '');
            //sprawdzamy czy plik zaczyna sie od http
            if (file.match("^http") || file.match("^mailto")) {
                window.open(file);
            }
            else {
                alert("Nie można otworzyć pliku z poziomu przeglądarki");
            }
        }
    },

    callAction: function (id, uuid, symbol, val) {
        console.info("Brak jeszcze implemenatcji funkcji: CallAction");
    },

    getResources: function (val) {
        var resource = {
            objectName: $(val).attr("objectname"),
            resourceType: $(val).attr("resourcetype"),
            resource: val,
            Id: $(val).attr("objectname") + "." + $(val).attr("resourcetype"),
        };

        App.Resources.add(resource);
    },

    copySizeFromParent: function (selector, parent) {
        var matched = $(selector, parent);
        matched.attr("style", "width:100%;height:100%;")
    },

    //Funkcja odpowiedzialna za obsługe klikniecia w przycisk
    buttonClick: function (actioninstanceid) {
        this.communication.BP.callAction(actioninstanceid);
    },

    initNavigator: function (xml) {
        this.navigator.init($('#SNAVIGATOR'), xml);
        this.navigator.formStack = FormStack;
        this.navigator.formStack.init(this.navigator);
    },

    getNotifications: function () {
        var self = this;
        window.setInterval(function () { self.communication.BP.getNotifications() }, this.notificationInterval);
    },

    createRandomData: function (count) {
        var imiona = ["Marcin", "Kuba", "Bartek", "Lukasz", "Piotrek", "Justyna", "Monika", "Gosia"];
        var nazwiska = ["Kowalski", "Nowak", "Bond"];
        var lata = ["12", "23", "34", "45", "56", "67", "78", "89"];
        var miasta = ["Wrocław", "Kraków", "Warszawa", "Poznań", "Katowice", "Gdańsk", "Lublin", "Częstochowa"];
        var ulice = ["Gwiazdzista", "Rychtalska", "Traugutta", "Jedności Narodowej", "Kazimierza Wielkiego", "Puławksiego", "Prądzyńskiego", "Tadeusza Kościuszki"];
        var datasource = [];

        for (var i = 0; i < count; i++) {
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var imie = imiona[rnd];
            var rnd = Math.ceil(Math.random() * 3) - 1;
            var nazwisko = nazwiska[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var wiek = lata[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var miasto = miasta[rnd];
            var rnd = Math.ceil(Math.random() * 8) - 1;
            var ulica = ulice[rnd];

            var osoba = new Object();
            osoba.imie = imie;
            osoba.nazwisko = nazwisko;
            osoba.wiek = wiek;
            osoba.miasto = miasto;
            osoba.ulica = ulica;

            datasource.push(osoba)
        }

        var object = { attr: 'a', attr1: 'b' }
        return datasource;
    },

    /**
     * Funkcja ustawia rozmiar glownego obszaru okien
     */
    resizeWrapper: function (width) {
        $("div#middle-pane").css("overflow-y", "auto");
        $("div#middle-pane").css("overflow-x", "hidden");
        var navHeight = $(".STOOLBAR").outerHeight(true) || 0;
        var bodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;//$("html").height();
        var middlePaneWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;//$("html").width();

        if (App.gui && App.gui.navigator && App.gui.navigator.pinned) {
            middlePaneWidth = middlePaneWidth - App.gui.navigator.widthPinMenu;
        }
        var bodyWidth = width || middlePaneWidth;

        $("div#wrapper").width(bodyWidth);
        $("div#wrapper").height(bodyHeight);
        $("div#middle-pane").height(bodyHeight - navHeight);
    },

    /**
     * Funkcja przełącza przeglądarkę w tryb pełnego ekranu
     */
    requestFullScreen: function () {
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);
      }
    },

    /**
     * Funkcja wychodzi z trybu pełnego ekranu
     */
    cancelFullScreen: function () {
      var doc = window.document;
      var docEl = doc.documentElement;

      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
        cancelFullScreen.call(doc);
      }
    },


    /**
     * Funkcja po zakonczeniu zmiany rozmiaru okna odpala funkcje resizeAll
     */
    resizing: function () {
        if (this.resizeTimer) {
            window.clearTimeout(this.resizeTimer);
        }
        this.resizeTimer = window.setTimeout(this.resizeAll, 200, this);
    },

    /**
     * Funkcja zmienia wymiary middle-pane po czym odświeża wszystkie formy
     * @param {object} target - obiekt gui
     */
    resizeAll: function (target) {
        if (target.resizeTimer) {
            window.clearTimeout(target.resizeTimer);
        }
        target.resizeWrapper();
        if (App.gui.navigator) {
          App.gui.navigator.resizeContent();
          App.gui.navigator.setForMenuScrollbarAfterWindowResize();
        }

    },

    setCssStyle: function () {
      var newStyle = Utils.operationsUrlParameters.getURLParameterValue("style");
      if (newStyle) {
        //ustalamy styl wg atrybutu ustawionego recznie
        if (newStyle == "tablet") {
          this.mobileVersion = true;
          this.device.tablet = true;
          this.device.mobile = false;
        } else if (newStyle == "mobile") {
          this.mobileVersion = true;
          this.device.tablet = false;
          this.device.mobile = true;
        } else {
          this.mobileVersion = false;
          this.device.tablet = false;
          this.device.mobile = false;
        }
      }
      this.currentCss = $('<link type="text/css" rel="stylesheet"/>');

      if (this.mobileVersion) {
          App.localStorage.setIfNotExist("pinMenu", "0");
          if (App.Settings.DevMode == "yes")
            this.currentCss.attr('href', App.Settings.CssVirtualPath + 'mobile.css');
          else
            this.currentCss.attr('href', App.Settings.CssVirtualPath + 'mobile.min.css');
          this.selectedCss = "mobileCss";
        //}
      } else {
        App.localStorage.setIfNotExist("pinMenu", "1");
        if (App.Settings.DevMode == "yes")
          this.currentCss.attr('href', App.Settings.CssVirtualPath + 'desktop.css');
        else
          this.currentCss.attr('href', App.Settings.CssVirtualPath + 'desktop.min.css');
        this.selectedCss = "desktopCss";
      }

      $('head').append(this.currentCss);
    },

    /**
     * Funkcja sluzy do dynamicznego dodawania plikow css skorek. Wybrane pliki css
     * zaleza od urzadzenia na jakim strona jest uruchomiona oraz czy aplikacja
     * zostala uruchomiona w trybie deweloperskim (nie uzywa plikow min).
     */
    setSkin: function () {
        //pobieramy z parametru skorke którą mamy wyświetlić
        var newSkin = Utils.operationsUrlParameters.getURLParameterValue("skin");
        var kendosentecss = 'kendo.sente.min.css';
        var kendosentedarkcss = 'kendo.sente-dark.min.css';
        var desktopbasiccss = 'sente.desktop.extension.min.css';
        var desktopdarkcss = 'sente.desktop.extension.dark.min.css';
        if (App.Settings.DevMode == "yes") {
            kendosentecss = 'kendo.sente.css';
            kendosentedarkcss = 'kendo.sente-dark.css';
            desktopbasiccss = 'sente.desktop.extension.css';
            desktopdarkcss = 'sente.desktop.extension.dark.css';
        }
        this.currentSkinCss = $('<link type="text/css" rel="stylesheet" />');
        this.currentSkinCss2 = $('<link type="text/css" rel="stylesheet" />');

        //Jeżeli w url nie podano skórki to ustawiamy domyślnie jasną
        if (!newSkin) {
            this.currentSkinCss.attr('href', App.Settings.CssVirtualPath + kendosentecss);
            if (!App.gui.mobileVersion) {
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopbasiccss)
            }
            this.selectedSkin = "sente";
        } else {
            //tworzymy style dla podanej skórki
            var skin = $('<link type="text/css" rel="stylesheet" />');

            if (newSkin == "dark") {
                skin.attr('href', App.Settings.CssVirtualPath + kendosentedarkcss);
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + kendosentedarkcss)
                if (!App.gui.mobileVersion) {
                    this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopdarkcss)
                }
                this.selectedSkin = "dark";
            }
            else {
                skin.attr('href', App.Settings.CssVirtualPath + kendosentecss);
                this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + kendosentecss)
                if (!App.gui.mobileVersion) {
                    this.currentSkinCss2.attr('href', App.Settings.CssVirtualPath + desktopbasiccss)
                }
                this.selectedSkin = "sente";
            }

            //usuwamy style dla obecnej skórki
            this.currentSkinCss.remove();

            //podpinamy style dla nowej skórki
            this.currentSkinCss = skin;
        }

        //dodajemy style do strony
        $('head').append(this.currentSkinCss);
        $('head').append(this.currentSkinCss2);
    },

    setProfileCss: function() {
        var profile = App.communication.getProfile();
        if (profile != "") {
            this.currentProfileCss = $('<link type="text/css" rel="stylesheet" />');
            this.currentProfileCss.attr('href', App.Settings.VirtualPath+'/$CSS$PROFILE/profile.css?profile='+profile);
            $('head').append(this.currentProfileCss);
        }
    },

    setStructure: function () {
        /*var newStructure = Utils.operationsUrlParameters.getURLParameterValue("structure");

        if (!newStructure) {
            if (this.device.desktop) {
                this.selectedStructure = "loose";
                this.structureMargin = 2 * this.margin;
                this.paddingForArtificialPanel = 16;
            }
            else {
                this.selectedStructure = "compact";
                this.structureMargin = this.margin;
                this.paddingForArtificialPanel = this.margin;
            }
        } else {
            if (newStructure == "loose") {
                this.structureMargin = 2 * this.margin;
                this.paddingForArtificialPanel = 16;
            }
            else {
                this.structureMargin = this.margin;
                this.paddingForArtificialPanel = this.margin;
            }

            this.selectedStructure = newStructure;
        }*/
        this.selectedStructure = "loose";
        this.structureMargin = 2 * this.margin;
        this.paddingForArtificialPanel = 16;

    },
    setFocusOnLoginForm: function () {
        if ($("#tbLogin").length) {
            var loginFromAppStorage = App.localStorage.get("login");
            if (loginFromAppStorage) {
                $("#tbLogin").val(loginFromAppStorage);
                $("#tbHaslo").focus();
            } else {
                $("#tbLogin").focus();
            }

        }
    },

    init: function (callback) {
        if (!this.initialized) {
            //ważna kolejność. Ostatni ma być setCssStyle ponieważ w nim jest wywoływana funkcja run
            this.setSkin();
            this.setProfileCss();
            this.setStructure();
            this.setFocusOnLoginForm();
            this.setCssStyle();
            this.initialized = true;
            if(callback)
              setTimeout(function() { callback() }, 100)
        }
    },

    registerDatavizTheme: function () {
        kendo.dataviz.ui.registerTheme('SenteDark', {
            "chart": {
                "title": {
                    "color": "#ffffff"
                },
                "legend": {
                    "labels": {
                        "color": "#ffffff"
                    }
                },
                "chartArea": {
                    "background": "#070707"
                },
                "seriesDefaults": {
                    "labels": {
                        "color": "#8a8a8a"
                    }
                },
                "axisDefaults": {
                    "line": {
                        "color": "#3f3f3f"
                    },
                    "labels": {
                        "color": "#8a8a8a"
                    },
                    "minorGridLines": {
                        "color": "#3f3f3f"
                    },
                    "majorGridLines": {
                        "color": "#3f3f3f"
                    },
                    "title": {
                        "color": "#8a8a8a"
                    }
                },
                "seriesColors": [
                    "#ffa810",
                    "#cc2167",
                    "#d5d7d8",
                    "#686868",
                    "#00b050",
                    "#00b0f0"
                ],
                "tooltip": {
                    "background": "",
                    "color": "",
                    "opacity": 1
                }
            },
            "gauge": {
                "pointer": {
                    "color": "#ffa810"
                },
                "scale": {
                    "rangePlaceholderColor": "#3f3f3f",
                    "labels": {
                        "color": "#8a8a8a"
                    },
                    "minorTicks": {
                        "color": "#3f3f3f"
                    },
                    "majorTicks": {
                        "color": "#3f3f3f"
                    },
                    "line": {
                        "color": "#3f3f3f"
                    }
                }
            }
        });
    },
    /**
    Funkcja ustawia stopkę na samym dole strony
    */
    setFooterInCorrectPlace: function () {
      var middlePaneHeight = $("#middle-pane").height() || 0;
      var footerHeight = $('.footer').height() || 0;
      var margin_top = middlePaneHeight - footerHeight;
        $('.footer').css('margin-top', margin_top);
    },

    /**
    * Metoda odpowiada za wygląd strony logowania. Jeżeli logowano się na profil pobiera jego nazwę
    * z serwera. Jeżeli znajdzie dla profilu ciasteczko wydłużonego logowania, ukrywa pola do wpisywania
    * loginu i hasła i wyświetla stosowny komunikat.
    * @instance
    */
    handleLoginPage: function () {
        var profile = Utils.operationsUrlParameters.getURLParameterValue("profile");

        if (!profile) {
            profile = App.Settings.Profile;
            if (profile) {
              Utils.operationsUrlParameters.addParam("profile", profile);
              //MS: tu był refreshUrl ale jest niepotrzebny bo w sytuacji istnienia profilu domyślnego w pliku smd pojawiało się wywołanie:
              //adres:9001//?profile=xxx
            }
        }

        if (profile) {
            App.communication.BP.getProfilName(profile);
        }
        else {
            $("#labelProfileName").text("");
            $("#labelProfileName").addClass("Hide");
            $("#mainMenuTitle").text("");
        }

        var self = this;
        var longAuth = neosLongAuthTokenPresent();

        if (longAuth) {
            $('#loginParams').hide();
            $('#loginMessage > p').text('Twoja sesja wygasła.');
        } else {
            $('#loginMessage').hide();
            $('#tbHaslo').pressEnter(doLog);
        }

        $('#btnLogin1').click(doLog);

        var $btnSwitch = $('#btnSwitchLoginMetod');

        if($btnSwitch.length>0) {
          $('#loginButtons').css('margin-bottom','40px');
        }

        $btnSwitch.click(function(){
          $('#loginButtons').css('margin-bottom','0px');
          $("#standardLogin").show();
          $('#btnSwitchLoginMetod').hide();
          $('#btnLogin1').text('Zaloguj kontem Sente');
        })

        /*
        * funkcja loguje do aplikacji, albo zakłada, że mamy prawo od razu wyświetlić stronę główną
        */
        function doLog(event) {
            if (longAuth) {
                App.sessionStorage.set("StartMethodExecuted", false);
                var url = location.origin;
                if (!url.endsWith("index.html"))
                    url += "/index.html";
                Utils.refreshURL(url);
            }
            else
                self.login($('#tbLogin').val(), $('#tbHaslo').val());
        }

        /**
        * funkcja sprawdza czy użytkownik posiada odpowiednie dla profilu ciasteczko uprawniające do automatycznego zalogowania
        */
        function neosLongAuthTokenPresent() {
            var key = "NeosAuthToken";
            if (profile) {
                key += "_" + profile;
            }

            var val = self.communication.getCookie(key);
            return (val && val.length > 0);
        }
    },
    /**
    * funkcja wyświetla dynamicznie zbudowane popupMenu.
    */
    showPopupMenu: function(id, elementUUID, val) {

        var form = this.getFormContainingDataset(id, 'showPopupMenu');
        var ds = form.datasets.getValue(id);
        var parentObj = ds.findInActionsObjectByElementUUID(elementUUID);
        var xml = $('div',val);
        var actID, actionuuid, label, icontext;

        // Jeśli nie znalazłem parenta to szukaj w drzewie DOM jako <button ....
        if(!parentObj){
            parentObj = $(document.getElementById(elementUUID))[0];
        } else
            parentObj = parentObj.objectElement[0];

        // Jeśli nie udało się wyszukać elementu który kliknąłem to dodaje nowy pusty
        // a pozycje X, Y pobiorę z ostatniego kliknięcia. Dynamiczne menu wywołane z popupMenu.
        if(!parentObj)
            parentObj = $('');

        parentObj.menu = $('<ul id="dynamicPopupMenu"/>');

        for (var i = 0; i < xml.length; i++) {
            actID = ($(xml[i]).attr('ID'));
            actionuuid = ($(xml[i]).attr('UUID'));
            label = ($(xml[i]).attr('L'));
            icontext = ($(xml[i]).attr('I'));

            // Tworze DatasetAction oraz dodaje do dataseta formy
            var action = ds.createDatasetAction(xml[i]);
            var li = $("<li data-bind='events:{ click: action_" + actID + "}' id='" + actID + "' actionInstanceId='" + actID + "' uuid = '" + actionuuid + "'><label>" + label + "</label></li>");
            var icon = Utils.getSpriteIcon(icontext, true);
            li.prepend(icon);
            $(li).data("SMenuButton", action);
            parentObj.menu.append(li);
        }
        // Naliczam model aby uwzględniał nowe akcje
        ds.createModel();
        kendo.bind(parentObj.menu, ds.model);

        parentObj.popup = $("<div class='menuPopup'/>");
        parentObj.popup.append(parentObj.menu);

        $(parentObj.popup).kendoPopup({
            anchor: parentObj.menu,
            animation: false,
            origin: "bottom right",
            position: "top right",
            deactivate: function(e) {
                //usuwam z drzewa DOM dodane popupMenu
                //usuwam parenta bo kendo diva z animacją
                parentObj.popup.parent().remove();
            }
        }).data("kendoPopup");

        $(document.body).append(parentObj.popup);

        parentObj.kendoPopup = parentObj.popup.data("kendoPopup");
        $(parentObj.menu).kendoMenu(
        {
            orientation: "vertical",
            animation: false,
            select: function onselect(e) {
                parentObj.kendoPopup.wrapper.hide();
                // event deactive nie jest uruchamiany po wykonaniu hide()
                parentObj.popup.parent().remove();
            }
        });

        // Pobieram pozycje elementu na oknie
        var myPos = this.getPosition(parentObj);
        if(myPos.x > 0 || myPos.y > 0)
            parentObj.kendoPopup.open(myPos.x, myPos.y);
        else
         // Jeśli nie udało się znaleść x, y to biorę ostatnie kliknięcie X, Y
            parentObj.kendoPopup.open(this.mouseLastPosX, this.mouseLastPosY);
    },

    //Zamyka popup menu.
    closePopupMenu: function() {
        $("ul.popupMenu").hide();
    },

    // Funkcja wylicza pozycje X Y przekazanego elementu
    getPosition: function(el) {
      var xPos = -1;
      var yPos = -1;
      // Dodaję wysokość samego elementu oraz odejmuję pixel aby dokleić do ramki przycisku.
      yPos += el.offsetHeight;
      while (el) {
        if (el.tagName == "BODY") {
          var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
          var yScroll = el.scrollTop || document.documentElement.scrollTop;

          xPos += (el.offsetLeft - xScroll + el.clientLeft);
          yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
          xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
          yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
      }
      return {
        x: xPos,
        y: yPos
      };
    }
}

function run() {//nie zmieniać nazwy funkcji
  App.communication.BP.getNeosVersion();
  $(document).ready(function () {
      App.gui.handleLoginPage();
  })
}

function onSignInByGoogle(googleUser) {
App.gui.loginByGoogle(googleUser);
}

function initialize() {
  Utils.checkIfBrowserIsSupported();
  Utils.operationsUrlParameters.initParamList();

  $.fn.pressEnter = function (fn) {
      return this.each(function () {
          $(this).bind('enterPress', fn);
          $(this).keyup(function (e) {
              if (e.keyCode == 13) {
                  $(this).trigger("enterPress");
              }
          })
      });
  };

  App = new App();
  App.communication.BP.getSettings();
}

initialize();
